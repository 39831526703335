<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const isDelete = ref(false)
const inputDelete = ref('')
const btnLoading = ref(false)

const accountToBeDeleted = computed(
  () => store.state.accounts.accountToBeDeleted
)

const accountToBeDeletedMeta = computed(
  () => store.state.accounts.accountToBeDeletedMeta
)

watch(inputDelete, () => {
  isDelete.value = inputDelete.value === 'DELETE'
})

const removeAccount = async () => {
  try {
    btnLoading.value = true
    await store.dispatch('accounts/deleteAccount', accountToBeDeleted.value)

    document.getElementById('closeModal')?.click()

    resetData()
    return true
  } catch (error) {
    return false
  }
}

const resetData = () => {
  btnLoading.value = false
  isDelete.value = false
  inputDelete.value = ''
}

onMounted(() => {
  document.addEventListener('hidden.bs.modal', () => {
    resetData()
    store.dispatch('accounts/clearAccountToBeDeleted')
  })
})
</script>

<template>
  <div
    id="deleteAccountModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteAccountModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="deleteAccountModalLabel" class="modal-title">
            {{ $t('Dashboard.Accounts.Cancellation.Box.Header') }}
          </h5>
          <button
            id="closeModal"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body text-left">
          <form novalidate @submit.prevent>
            <div class="input-wrapper">
              <i18n-t
                keypath="Dashboard.Accounts.Cancellation.Box.Sub"
                tag="p"
                scope="global"
              >
                <template #account>
                  <strong>
                    {{ accountToBeDeletedMeta.type }}
                    {{ accountToBeDeletedMeta.ref }}
                  </strong>
                </template>
              </i18n-t>
              <label for="closeInput" class="fw-semibold mb-1">
                {{ $t('Dashboard.Accounts.Cancellation.Box.Label') }}
              </label>
              <input
                id="deleteInput"
                v-model="inputDelete"
                type="text"
                autocomplete="off"
                autofocus
                class="form-control closeBox"
                name="inputDelete"
                :aria-label="$t('Dashboard.Accounts.DeleteAccountAriaLabel')"
                :placeholder="$t('Dashboard.Accounts.DeletePlaceholder')"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            id="closeAccount"
            class="sa-btn sa-btn-danger sa-btn-sm"
            type="submit"
            :disabled="!isDelete || btnLoading"
            @click.prevent="removeAccount"
          >
            <div
              v-if="btnLoading"
              class="spinner sa-btn-spinner"
              role="alert"
              :aria-label="$t('Accessibility.Button.Loading')"
            ></div>
            <span v-else>{{ $t('Buttons.Confirm') }}</span>
          </button>
          <button
            type="button"
            class="sa-btn sa-btn-secondary sa-btn-sm"
            data-bs-dismiss="modal"
          >
            {{ $t('Buttons.Cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
