<script setup lang="ts">
import { useStore } from 'vuex'

const store = useStore()

defineProps({
  rowData: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <div class="badge-column-wrapper d-flex flex-column align-items-end">
    <button
      v-if="rowData.period"
      class="btn btn-xs btn-primary"
      @click="store.dispatch('printStatement', rowData)"
    >
      {{ $t('Dashboard.Statements.PrintButton') }}
    </button>
  </div>
</template>
