import { DateTime } from 'luxon'

export const moneyFormat = (value: number) => {
  try {
    const val = (value / 100).toFixed(2)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } catch (e) {
    console.log(e)
  }
}

export const dateFormat = (date: string) => {
  try {
    return DateTime.fromISO(date).toFormat('d MMMM yyyy')
  } catch (e) {
    console.error(e)
  }
}
