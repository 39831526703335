<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { DateTime } from 'luxon'
import { useRouter } from 'vue-router'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import giftaidItBlack from '@/assets/images/giftaidit-black.svg'
import Header from '@/components/views/manage-account/Header.vue'

const store = useStore()
const router = useRouter()

const date = ref(DateTime.local().toFormat('yyyy-MM-dd'))

const isLoading = computed(() => store.state.isLoading)

onMounted(() => {
  store.commit('SET_LOADING', false)
})

const sendSuspendDate = async () => {
  await store.dispatch('deleteAuth', {
    endDate: date.value,
  })

  date.value = ''
  store.commit('CLEAR_SWIFTAID_AUTHORISATION')
  router.push({ name: 'DONOR_FLOW_ManageAccountSuspendConfirm' })
}
</script>

<template>
  <div class="container">
    <Header
      :img-src="giftaidItBlack"
      img-alt="giftaid it"
      img-width="142px"
      img-margin-bottom="mb-4"
      :header="$t('ManageAccount.SuspendAccountCalendar.Header')"
      class="w-lg-75 m-auto text-balance"
    />

    <div class="row mb-4">
      <div class="col col-md-12 mx-auto">
        <div class="d-flex flex-column align-items-center text-center">
          <VueDatePicker
            v-model="date"
            model-type="yyyy-MM-dd"
            inline
            auto-apply
            :enable-time-picker="false"
            :min-date="new Date()"
            class="mb-4 mx-auto w-auto"
          />

          <p
            class="mb-4 text-balance"
            v-text="$t('ManageAccount.SuspendAccountCalendar.Paragraph')"
          ></p>

          <button
            id="confirm"
            :aria-label="$t('Dashboard.Settings.Auth.ButtonLabel')"
            class="sa-btn sa-btn-sm sa-btn-primary-solid"
            :disabled="isLoading"
            @click="sendSuspendDate"
          >
            <div v-if="isLoading" class="d-flex align-items-center">
              <div
                class="spinner sa-btn-spinner me-2"
                role="alert"
                :aria-label="$t('Accessibility.Button.Loading')"
              ></div>
              {{
                $t('ManageAccount.SuspendAccountCalendar.Button.LoadingText')
              }}
            </div>
            <div v-else>
              <span
                v-text="$t('ManageAccount.SuspendAccountCalendar.Button.Text')"
              ></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
:root {
  /*General*/
  --dp-font-family: var(--main-font-family);
  --dp-border-radius: 5px; /*Configurable border-radius*/
  --dp-cell-border-radius: 5px; /*Specific border radius for the calendar cell*/
  --dp-common-transition: all 0.1s ease-in; /*Generic transition applied on buttons and calendar cells*/

  /*Sizing*/
  --dp-button-height: 35px; /*Size for buttons in overlays*/
  --dp-month-year-row-height: 35px; /*Height of the month-year select row*/
  --dp-month-year-row-button-size: 35px; /*Specific height for the next/previous buttons*/
  --dp-button-icon-height: 20px; /*Icon sizing in buttons*/
  --dp-cell-size: 35px; /*Width and height of calendar cell*/
  --dp-cell-padding: 5px; /*Padding in the cell*/
  --dp-common-padding: 10px; /*Common padding used*/
  --dp-input-icon-padding: 35px; /*Padding on the left side of the input if icon is present*/
  --dp-input-padding: 6px 30px 6px 12px; /*Padding in the input*/
  --dp-menu-min-width: 250px; /*Adjust the min width of the menu*/
  --dp-action-buttons-padding: 2px 5px; /*Adjust padding for the action buttons in action row*/
  --dp-row-margin: 5px 0; /*Adjust the spacing between rows in the calendar*/
  --dp-calendar-header-cell-padding: 0.5rem; /*Adjust padding in calendar header cells*/
  --dp-two-calendars-spacing: 10px; /*Space between multiple calendars*/
  --dp-overlay-col-padding: 3px; /*Padding in the overlay column*/
  --dp-time-inc-dec-button-size: 32px; /*Sizing for arrow buttons in the time picker*/
  --dp-menu-padding: 6px 8px; /*Menu padding*/

  /*Font sizes*/
  --dp-font-size: 1rem; /*Default font-size*/
  --dp-preview-font-size: 0.8rem; /*Font size of the date preview in the action row*/
  --dp-time-font-size: 0.8rem; /*Font size in the time picker*/

  /*Transitions*/
  --dp-animation-duration: 0.1s; /*Transition duration*/
  --dp-menu-appear-transition-timing: cubic-bezier(
    0.4,
    0,
    1,
    1
  ); /*Timing on menu appear animation*/
  --dp-transition-timing: ease-out; /*Timing on slide animations*/
}

@media screen and (min-width: 450px) {
  :root {
    --dp-menu-min-width: 400px;
  }
}

@media screen and (min-width: 600px) {
  :root {
    --dp-menu-min-width: 500px;
  }
}

.dp__theme_light {
  --dp-background-color: var(--white);
  --dp-text-color: var(--black);
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: var(--swiftaid-mid-blue);
  --dp-primary-disabled-color: #81c2e0;
  --dp-primary-text-color: #f8f5f5;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-border-color-focus: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: var(--theme-green);
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: var(--theme-red);
  --dp-marker-color: var(--theme-red);
  --dp-tooltip-color: #fafafa;
  --dp-disabled-color-text: #8e8e8e;
  --dp-highlight-color: rgb(25 118 210 / 10%);
  --dp-range-between-dates-background-color: var(--dp-hover-color, #f3f3f3);
  --dp-range-between-dates-text-color: var(--dp-hover-text-color, #212121);
  --dp-range-between-border-color: var(--dp-hover-color, #f3f3f3);
}
</style>
