<template>
  <div class="row" data-testid="manage-account-info-links">
    <div class="col">
      <h2 class="fw-bold text-center text-balance">
        {{ $t('ManageAccount.InfoLinks.Title') }}
      </h2>

      <router-link
        :to="{ name: 'DONOR_FLOW_ManageAccountSuspend' }"
        class="info-link"
        data-testid="manage-account-suspend-info-link"
      >
        <div>
          <h3>
            {{ $t('ManageAccount.InfoLinks.SuspendAccountLink.Title') }}
          </h3>
          <p>
            {{ $t('ManageAccount.InfoLinks.SuspendAccountLink.Description') }}
          </p>
        </div>
        <div>
          <i class="fas fa-chevron-right"></i>
        </div>
      </router-link>

      <hr class="text-secondary text-opacity-50" />

      <a
        href="https://www.swiftaid.co.uk/donors/manage-account/close/"
        class="info-link"
        data-testid="manage-account-close-info-link"
      >
        <div>
          <h3>
            {{ $t('ManageAccount.InfoLinks.CloseAccountLink.Title') }}
          </h3>
        </div>
        <div>
          <i class="fas fa-chevron-right"></i>
        </div>
      </a>

      <hr class="text-secondary text-opacity-50" />

      <a
        href="https://www.swiftaid.co.uk/donors/manage-account/something-else/"
        class="info-link"
        data-testid="manage-account-something-else-info-link"
      >
        <div>
          <h3>
            {{ $t('ManageAccount.InfoLinks.SomethingElseLink.Title') }}
          </h3>
        </div>
        <div>
          <i class="fas fa-chevron-right"></i>
        </div>
      </a>
    </div>
  </div>
</template>

<style scoped>
a {
  color: var(--dark);
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

.info-link {
  --border-radius: 5px;
  text-decoration: none;
}

a.info-link:first-of-type {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

a.info-link:last-of-type {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

a:hover {
  background-color: var(--light-grey);
  color: var(--black);
}

a:hover i {
  color: var(--link-color-hover);
}

h3 {
  font-size: 1rem;
  margin-bottom: 0;
  min-height: 24px;
  line-height: 24px;
}

p {
  font-size: 0.85rem;
  margin-bottom: 0;
}

hr {
  margin: 0;
}
</style>
