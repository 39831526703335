<script setup lang="ts">
import { ref } from 'vue'
import DetailsForm from '@/views/manage-account/create/details.vue'
import PersonalDetails from '@/components/views/dashboard/settings/PersonalDetails.vue'

const editInfo = ref(false)

const toggleEdit = () => {
  editInfo.value = !editInfo.value
}
</script>

<template>
  <div id="personal-details-section" class="mb-4">
    <div class="bg-white p-3 p-md-4">
      <h2 class="header-text">
        {{ $t('Dashboard.Settings.Personal.Details') }}
      </h2>
      <div v-if="editInfo">
        <div class="personal-wrapper">
          <DetailsForm @click="toggleEdit" />
        </div>
      </div>
      <div v-else>
        <PersonalDetails />
        <button id="edit" class="btn btn-primary btn-sm" @click="toggleEdit">
          {{ $t('Buttons.Edit') }}
        </button>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <button
          v-if="editInfo"
          class="sa-btn text-link text-secondary"
          @click="toggleEdit"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.personal-wrapper {
  .form-wrapper {
    margin: inherit;

    @media (max-width: 575.98px) {
      width: 100%;
    }
  }
}
</style>
