<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import LoginStart from '@/components/views/manage-account/LoginStart.vue'
import LoginEnd from '@/components/views/manage-account/LoginEnd.vue'

const store = useStore()

const formSubmitted = computed(() => store.state.auth.formSubmitted)
</script>

<template>
  <div class="auth-layout">
    <div class="w-100">
      <LoginStart v-if="!formSubmitted" />
      <LoginEnd v-else />
    </div>
  </div>
</template>
