<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import warningTriangle from '@/assets/images/warning-triangle.svg'
import Header from '@/components/views/manage-account/Header.vue'

const store = useStore()
const router = useRouter()

const btnLoading = ref(false)

const removeAccount = async () => {
  try {
    btnLoading.value = true
    await store.dispatch('deleteSwiftAidAccount')
    btnLoading.value = false
    router.push({ name: 'DONOR_FLOW_ManageAccountCloseConfirm' })
  } catch (error) {
    console.error(error)
    store.dispatch('setGlobalError', 'An error occurred')
    btnLoading.value = false
  }
}

onMounted(() => {
  store.dispatch('setLoading', false)
})
</script>

<template>
  <div class="container">
    <Header
      :img-src="warningTriangle"
      img-alt="Warning"
      img-width="84px"
      img-margin-bottom="mb-4"
      :header="$t('ManageAccount.CloseAccount.Header')"
    />

    <div class="row mb-4">
      <div class="col col-md-8 mx-auto">
        <div class="d-flex justify-content-center">
          <a
            href="https://swiftaid.co.uk/donors/manage-account/"
            class="sa-btn sa-btn-secondary sa-btn-lg w-25 m-1 text-decoration-none"
            aria-label="Cancel closing account"
            v-text="$t('ManageAccount.CloseAccount.Cancel')"
          ></a>
          <button
            class="sa-btn sa-btn-secondary sa-btn-lg w-25 m-1"
            aria-label="Agree close account"
            @click="removeAccount"
            :disabled="btnLoading"
          >
            <div v-if="btnLoading" class="d-flex align-items-center">
              <div
                class="sa-btn-spinner me-2"
                role="alert"
                :aria-label="$t('Accessibility.Button.Loading')"
              ></div>
              Closing
            </div>
            <div v-else>
              <span>{{ $t('ManageAccount.CloseAccount.Confirm') }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
