import sortBy from 'lodash/sortBy'
import { EmailAccount } from './account-types'
import {
  CLEAR_ACCOUNTS,
  SET_ACCOUNT,
  SET_FIDEL_ACCOUNT,
  SET_CARD_ACCOUNT,
  SET_PAR_ACCOUNT,
  SET_EMAIL_ACCOUNT,
  SET_PHONE_ACCOUNT,
  DELETE_ACCOUNT,
  SET_ACCOUNT_TO_DELETE,
  SET_ACCOUNT_TO_DELETE_META,
  SET_CURRENT_ACCOUNT_ID,
  SET_ACCOUNTS_BUSY,
  UPDATE_PRIMARY_EMAIL_ACCOUNT,
} from '@/store/mutation-types'

export default {
  [CLEAR_ACCOUNTS](state: any) {
    state.accounts = []
    state.fidelAccounts = []
    state.cardAccounts = []
    state.parAccounts = []
    state.emailAccounts = []
    state.phoneAccounts = []
  },
  [SET_FIDEL_ACCOUNT](state: any, payload: any) {
    state.fidelAccounts.push(payload)
  },
  [SET_CARD_ACCOUNT](state: any, payload: any) {
    state.cardAccounts.push(payload)
  },
  [SET_PAR_ACCOUNT](state: any, payload: any) {
    state.parAccounts.push(payload)
  },
  [SET_EMAIL_ACCOUNT](state: any, payload: any) {
    state.emailAccounts.push(payload)
  },
  [SET_PHONE_ACCOUNT](state: any, payload: any) {
    state.phoneAccounts.push(payload)
  },
  [SET_ACCOUNT](state: any, payload: any) {
    state.accounts.push(payload)
    state.hasAccounts = Array.isArray(state.accounts) && state.accounts.length
  },
  [DELETE_ACCOUNT](state: any) {
    const {
      accounts,
      fidelAccounts,
      cardAccounts,
      parAccounts,
      emailAccounts,
      accountToBeDeleted,
      phoneAccounts,
    } = state

    state.accounts = accounts.filter(
      (acc: any) => acc.id !== accountToBeDeleted
    )
    state.fidelAccounts = fidelAccounts.filter(
      (acc: any) => acc.id !== accountToBeDeleted
    )
    state.cardAccounts = cardAccounts.filter(
      (acc: any) => acc.id !== accountToBeDeleted
    )
    state.parAccounts = parAccounts.filter(
      (acc: any) => acc.id !== accountToBeDeleted
    )
    state.emailAccounts = emailAccounts.filter(
      (acc: any) => acc.id !== accountToBeDeleted
    )
    state.phoneAccounts = phoneAccounts.filter(
      (acc: any) => acc.id !== accountToBeDeleted
    )
  },
  [SET_ACCOUNT_TO_DELETE](state: any, payload: any) {
    state.accountToBeDeleted = payload
  },
  [SET_ACCOUNT_TO_DELETE_META](state: any, payload: any) {
    state.accountToBeDeletedMeta = payload
  },
  [SET_CURRENT_ACCOUNT_ID](state: any, payload: any) {
    state.currentAccountId = payload
  },
  [SET_ACCOUNTS_BUSY](state: any, payload: any) {
    state.accountsBusy = payload
  },
  [UPDATE_PRIMARY_EMAIL_ACCOUNT](state: any, payload: any) {
    const updatedEmailAccounts = state.emailAccounts.map((account: any) => {
      const newAccount = new EmailAccount(account)
      newAccount.tagAsPrimary(payload)
      return newAccount.output()
    })

    state.emailAccounts = sortBy(updatedEmailAccounts, 'isPrimary').reverse()
  },
}
