<script setup lang="ts">
import { ref, computed, onMounted, type Ref, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import ApiErrorNotification from '@/components/generic/ApiErrorNotification.vue'
import AnimatedEllipsis from '@/components/generic/AnimatedEllipsis.vue'
import { moneyFormat } from '@/components/helpers/formats'
import Statement from '@/components/views/dashboard/statements/Statement.vue'
import PrintStatementButton from '@/components/views/dashboard/statements/PrintStatementButton.vue'
import type { DonorInformation } from '@/types'

type Statement = {
  id: string
  period: string
  totalDonated: number
  totalGiftAid: number
  issued: string
  donor: DonorInformation
}

const store = useStore()

const apiError: Ref<boolean> = ref(false)

const userStatements: ComputedRef<Statement[]> = computed(
  () => store.getters.userStatements
)
const token: ComputedRef<string> = computed(() => store.getters.token)

onMounted(async () => {
  if (token.value && isEmpty(userStatements.value)) {
    try {
      await store.dispatch('fetchUserStatements')
    } catch (error) {
      console.error(error)
      apiError.value = true
    }
  }
  setTimeout(() => {
    store.commit('SET_LOADING', false)
  }, 500)
})
</script>

<template>
  <div role="main">
    <ApiErrorNotification
      v-if="apiError"
      link="Statements"
      aria-label="Statements API error"
    >
      An error has occurred while fetching statements, please
      <strong>try again</strong>
      .
    </ApiErrorNotification>
    <transition v-else name="fade" mode="out-in" appear>
      <div
        v-if="userStatements && userStatements.length > 0"
        id="statementTableWrapper"
        class="mb-4"
      >
        <div>
          <div class="table-responsive">
            <table class="statement-table table">
              <thead>
                <tr>
                  <th>
                    {{ $t('Dashboard.Statements.TableHeadings.StatementYear') }}
                  </th>
                  <th>
                    {{ $t('Dashboard.Statements.TableHeadings.TotalDonated') }}
                  </th>
                  <th>
                    {{ $t('Dashboard.Statements.TableHeadings.GiftAid') }}
                  </th>
                  <th><span class="sr-only">Actions</span></th>
                </tr>
              </thead>
              <tbody v-if="userStatements">
                <tr v-for="statement in userStatements" :key="statement.id">
                  <td>{{ statement.period }}</td>
                  <td>£{{ moneyFormat(statement.totalDonated) }}</td>
                  <td>£{{ moneyFormat(statement.totalGiftAid) }}</td>
                  <td class="text-right">
                    <PrintStatementButton :row-data="statement" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="4 pulse">
                    {{ $t('Dashboard.Statements.FetchingStatements') }}
                    <AnimatedEllipsis />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        v-else
        id="placeHolderWrapper"
        class="d-flex justify-content-center my-5"
      >
        <div class="d-flex flex-column col-sm-6 col-md-6 col-lg-5">
          <img
            id="noStatement"
            class="mb-4 ml-auto mr-auto"
            src="@/assets/images/main/statement.svg"
            :alt="$t('Dashboard.Statements.Image.Statement')"
          />
          <h1 class="mb-4 text-center">
            {{ $t('Dashboard.Statements.NotTitle') }}
          </h1>
          <p id="notSub" class="mb-4 text-center">
            {{ $t('Dashboard.Statements.NotSub') }}
          </p>
        </div>
      </div>
    </transition>
    <Statement />
  </div>
</template>

<style lang="scss" scoped>
.table {
  th,
  td {
    padding: 0.75rem 0.1rem;
    vertical-align: middle;
    text-transform: uppercase;
  }

  th {
    font-size: 0.65rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td {
    font-size: 0.75rem;
  }

  @media (min-width: 576px) {
    th,
    td {
      padding: 0.75rem;
      font-size: 1rem;
      text-transform: none;
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    .btn {
      border: none;
      color: var(--brand-primary);
      font-size: 0.75rem !important;
      padding: 0 5px !important;
      text-transform: uppercase;
    }
  }
}
</style>
