<script setup lang="ts">
defineProps({
  totalGiftAid: {
    type: Number,
    required: true,
    default: 0,
  },
  totalDonations: {
    type: Number,
    required: true,
    default: 0,
  },
})

const formatMoney = (value: number) => {
  return Number.isInteger(value)
    ? (value / 100).toFixed(2).split('.')
    : ['0', '00']
}
</script>

<template>
  <div class="row top-widgets">
    <div class="col-sm-6 donation-column">
      <div class="donations-box">
        <div class="donations p-2">
          <h3 class="total-donation text-center">
            <span class="pound-sign">£</span>
            {{ formatMoney(totalDonations)[0] }}
            <span class="pound-sign decimal">
              .{{ formatMoney(totalDonations)[1] }}
            </span>
          </h3>
          <p class="donation-label mb-0 text-center">
            {{ $t('Dashboard.Donations.Summary.Header.Donated') }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-sm-6 donation-column">
      <div class="donations-box">
        <div class="donations p-2">
          <h3 class="giftaid-donation text-center">
            <span class="pound-sign">£</span>
            {{ formatMoney(totalGiftAid)[0] }}
            <span class="pound-sign decimal">
              .{{ formatMoney(totalGiftAid)[1] }}
            </span>
          </h3>
          <p class="donation-label mb-0 text-center">
            {{ $t('Dashboard.Donations.Summary.Header.GiftAid') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-widgets {
  margin-bottom: var(--spacer);
}

.donations-box {
  height: 100%;
  padding: var(--spacer);
  background-color: var(--white);
  border-bottom: 1px solid var(--light-grey);

  .donations {
    .total-donation,
    .giftaid-donation {
      margin-bottom: 0;
      font-size: 48px;
      line-height: 1;

      @media (min-width: 576px) {
        font-size: 38px;
      }
    }

    .giftaid-donation {
      color: var(--brand-primary);
    }
  }

  .pound-sign {
    font-size: 22px;
    margin-right: -9px;

    &.decimal {
      margin-left: -7px;
      margin-right: 0;
    }
  }
}
</style>
