<script setup lang="ts">
import { ref, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()

const isDelete = ref(false)
const inputDelete = ref('')
const btnLoading = ref(false)

watch(inputDelete, () => {
  isDelete.value = inputDelete.value === 'DELETE'
})

const removeAccount = async () => {
  btnLoading.value = true
  try {
    await store.dispatch('deleteSwiftAidAccount')
    document.getElementById('closeModal')?.click()
    btnLoading.value = false
    isDelete.value = false
    inputDelete.value = ''

    await store.dispatch('auth0Logout')
    router.push({ name: 'DONOR_FLOW_ManageAccountLogin' })
  } catch (error) {
    console.error(error)
  }
}

onMounted(() => {
  const deleteAccountModal = document.getElementById('deleteAccountModal')

  deleteAccountModal?.addEventListener('shown.bs.modal', () => {
    const deleteInput = document.getElementById('deleteInput')
    deleteInput?.focus()
  })
})
</script>

<template>
  <div
    id="deleteAccountModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="deleteAccountModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="deleteAccountModalLabel" class="modal-title">
            {{ $t('Dashboard.Settings.Cancellation.Box.Header') }}
          </h5>
          <button
            id="closeModal"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body text-left">
          <form novalidate @submit.prevent>
            <div class="input-wrapper">
              <p>{{ $t('Dashboard.Settings.Cancellation.Box.Sub') }}</p>
              <label for="closeInput">
                {{ $t('Dashboard.Settings.Cancellation.Box.Label') }}
              </label>
              <input
                id="deleteInput"
                v-model="inputDelete"
                type="text"
                autocomplete="off"
                autofocus
                class="form-control closeBox"
                name="inputDelete"
                :aria-label="
                  $t('Dashboard.Settings.DeleteSwiftaidAccountAriaLabel')
                "
                :placeholder="$t('Dashboard.Settings.DeletePlaceholder')"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            id="closeAccount"
            class="btn-danger btn btn-sm"
            type="submit"
            :disabled="!isDelete || btnLoading"
            @click.prevent="removeAccount"
          >
            <div
              v-if="btnLoading"
              class="spinner btn-spinner"
              role="alert"
              :aria-label="$t('Accessibility.Button.Loading')"
            ></div>
            <span v-else>{{ $t('Buttons.Confirm') }}</span>
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            data-bs-dismiss="modal"
          >
            {{ $t('Buttons.Cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
