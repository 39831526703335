import {
  SET_AUTH,
  SET_TOKEN,
  TOGGLE_LOGIN_METHOD,
  RESET_LOGIN_METHOD,
  SET_EMAIL_SENT_STATUS,
  SET_FORM_SUBMITTED_STATUS,
  SET_AUTH_EMAIL,
  SET_BUTTON_LOADING_STATUS,
  SET_IS_VALID_EMAIL,
  SET_FORM_CHANGED_STATUS,
  RESET_REGISTRATION_STATE,
  SET_CALLBACK_LANDING_PAGE,
  RESET_CALLBACK_LANDING_PAGE,
} from '@/store/mutation-types'

export default {
  [SET_AUTH](state: any, payload: any) {
    state.authenticated = payload
  },
  [SET_TOKEN](state: any, payload: any) {
    state.idToken = payload
  },
  [TOGGLE_LOGIN_METHOD](state: any) {
    state.loginMethod = state.loginMethod === 'code' ? 'link' : 'code'
  },
  [RESET_LOGIN_METHOD](state: any) {
    state.loginMethod = 'code'
  },
  [SET_EMAIL_SENT_STATUS](state: any, payload: any) {
    state.emailSent = payload
  },
  [SET_FORM_SUBMITTED_STATUS](state: any, payload: any) {
    state.formSubmitted = payload
  },
  [SET_AUTH_EMAIL](state: any, payload: any) {
    state.authEmail = payload
  },
  [SET_BUTTON_LOADING_STATUS](state: any, payload: any) {
    state.btnLoading = payload
  },
  [SET_IS_VALID_EMAIL](state: any, payload: any) {
    state.isValidEmail = payload
  },
  [SET_FORM_CHANGED_STATUS](state: any, payload: any) {
    state.hasFormChanged = payload
  },
  [RESET_REGISTRATION_STATE](state: any) {
    localStorage.removeItem('loggedIn')
    localStorage.removeItem('expires_at')
    state.emailSent = false
    state.formSubmitted = false
    state.hasFormChanged = false
    state.isValidEmail = false
    state.authEmail = ''
    state.btnLoading = false
  },
  [SET_CALLBACK_LANDING_PAGE](state: any, payload: any) {
    state.callbackLandingPage = payload
    sessionStorage.setItem('callbackLandingPage', payload)
  },
  [RESET_CALLBACK_LANDING_PAGE](state: any) {
    state.callbackLandingPage = ''
    sessionStorage.removeItem('callbackLandingPage')
  },
}
