export default {
  token: (state: any) => {
    if (localStorage.getItem('testEnv')) {
      return localStorage.getItem('token_id')
    }

    return state.idToken
  },
  isAuth: (state: any) => state.authenticated,
}
