export default {
  sidebar: {
    opened: false,
  },
  appLoading: true,
  isLoading: true,
  requestLoading: true,
  highlighted: {
    dates: [new Date()],
  },
  disabledDates: {
    to: new Date(Date.now() + -1 * 23 * 3600 * 1000),
  },
  globalError: null,
  isSettingsPage: false,
  addAccountType: '',
  printData: null,
  mode: import.meta.env.VITE_NODE_ENV,
  termsModalIsVisible: false,
  privacyModalIsVisible: false,
  referralEmail: '',
  maintenance: !!import.meta.env.VITE_MAINTENANCE,
}
