<script setup lang="ts">
import { computed, onBeforeMount, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import TermsModal from '@/components/views/login-registration/modals/TermsModal.vue'
import PrivacyModal from '@/components/views/login-registration/modals/PrivacyModal.vue'

const store = useStore()
const route = useRoute()

const authenticated = computed(() => store.state.auth.authenticated)
const requestLoading = computed(() => store.state.requestLoading)
const termsModalIsVisible = computed(() => store.state.termsModalIsVisible)
const privacyModalIsVisible = computed(() => store.state.privacyModalIsVisible)
const showButton = computed(
  () => route.name !== 'DONOR_FLOW_ManageAccountUserNotFound'
)

onBeforeMount(() => {
  document.body.classList.add('manage-account')
})

onUnmounted(() => {
  document.body.classList.remove('manage-account')
})

const logout = () => {
  store.dispatch('auth0Logout', 'https://www.swiftaid.co.uk')
}

const goHome = () => {
  window.location.href = 'https://www.swiftaid.co.uk/donors'
}
</script>

<template>
  <div>
    <div class="support-navigation">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
          <button class="support-navigation__back-button" @click="goHome">
            <i class="fas fa-chevron-left fa-xs"></i>
            Manage account
          </button>
          <div v-if="showButton" class="small">
            <a
              v-if="authenticated"
              href="#"
              class="ms-3 fw-bold"
              @click.prevent="logout"
            >
              Logout
            </a>
            <router-link
              v-else
              class="ms-3 fw-bold"
              :to="{ name: 'DONOR_FLOW_ManageAccountLogin' }"
            >
              Login
            </router-link>
          </div>
        </nav>
      </div>
    </div>

    <div
      v-if="requestLoading"
      class="align-items-center bottom-0 d-flex end-0 position-absolute start-0 top-0"
    >
      <div class="spinner"></div>
    </div>

    <section v-else class="hero-area">
      <div class="container">
        <router-view role="main"></router-view>
      </div>
    </section>
    <TermsModal v-if="termsModalIsVisible" />
    <PrivacyModal v-if="privacyModalIsVisible" />
  </div>
</template>

<style lang="scss">
body.manage-account::-webkit-scrollbar {
  width: 1em;
}

body.manage-account::-webkit-scrollbar-track {
  box-shadow: none;
}

body.manage-account::-webkit-scrollbar-thumb {
  background-color: var(--mid-grey);
}

.hero-area {
  padding-top: 100px;

  @media (min-width: 768px) {
    padding-top: 200px;
  }
}

.support-navigation {
  .navbar {
    height: auto;
    box-shadow: none;
    background-color: transparent;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar .small {
    transform: scale(0.8);
  }

  &__back-button {
    font-weight: 700;
    display: flex;
    align-items: center;
    color: var(--mid-grey);
    background-color: rgba(var(--white), 0.8);
    border: none;
    padding: 10px 20px;
    transition: color 0.2s ease-in-out;
    transition-delay: initial;
    border-radius: 5px;

    .fas {
      margin-right: 5px;
    }

    &:hover {
      color: darken(#b4b4b4, 20%);
    }
  }
}
</style>
