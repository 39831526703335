<script setup lang="ts">
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { codeSchema } from '@/types'
import FieldError from '@/components/views/manage-account/FieldError.vue'

const store = useStore()

const validationSchema = toTypedSchema(codeSchema)
const { handleSubmit, errors, defineField } = useForm({
  validationSchema,
})
const [code] = defineField('code')

const buttonLoadingState = ref(false)

const email = computed(() => store.state.auth.authEmail)

const loginWithCode = handleSubmit(async (values) => {
  buttonLoadingState.value = true

  try {
    await store.dispatch('auth0PasswordlessLogin', {
      email: email.value,
      verificationCode: values.code,
    })
  } catch (error) {
    buttonLoadingState.value = false
  }

  buttonLoadingState.value = false
})
</script>

<template>
  <form novalidate @submit.prevent="loginWithCode">
    <label
      for="code"
      class="mb-2 d-block text-center"
      v-text="$t('ManageAccount.Login.CodeForm.FieldLabel')"
    ></label>

    <input
      id="code"
      v-model="code"
      spellcheck="false"
      type="text"
      class="form-control form-control-lg text-center"
      :class="{ 'is-invalid': errors.code?.length }"
      name="code"
      minlength="6"
      maxlength="6"
      autocomplete="one-time-code"
      :placeholder="$t('ManageAccount.Login.CodeForm.FieldPlaceholder')"
    />

    <FieldError :error="errors.code" class="error" />

    <div class="d-flex flex-column align-items-center mt-4">
      <button
        type="submit"
        class="sa-btn sa-btn-primary-solid sa-btn-lg mb-2 sa-btn-block w-75"
        :disabled="buttonLoadingState"
      >
        <div v-if="buttonLoadingState" class="d-flex align-items-center">
          <div class="spinner sa-btn-spinner me-2" role="alert"></div>
          <div>
            {{ $t('ManageAccount.Login.CodeForm.Button.LoadingText') }}
          </div>
        </div>
        <div
          v-else
          v-text="$t('ManageAccount.Login.CodeForm.Button.Text')"
        ></div>
      </button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
#code,
button[type='submit'],
.error {
  max-width: 220px;
  margin: auto;
}
</style>
