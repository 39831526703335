<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { DateTime } from 'luxon'

const store = useStore()
const router = useRouter()

const date = ref('')
const datePicked = ref(false)
const formattedDate = ref('')
const openDate = ref(new Date().toString())
const todayDate = ref('')
const expiredDate = ref('')

const authorisation = computed(() => store.state.donor.authorisation)
const swiftaidAuthorised = computed(() => store.state.swiftaidAuthorised)
const requestLoading = computed(() => store.state.requestLoading)

watch(authorisation.value, () => {
  formatDateOnRefresh()
})

const fetchAuth = () => {
  store.dispatch('fetchAuth')
}

const clearSwiftaidAuthorisation = () => {
  store.commit('CLEAR_SWIFTAID_AUTHORISATION')
}

const renewAuth = () => {
  router.push({ name: 'Renew' }).catch(() => {})
}

const sendDeactivate = async () => {
  datePicked.value = false

  try {
    await store.dispatch('deleteAuth', {
      endDate: sendDate(date.value),
    })
    clearSwiftaidAuthorisation()
    fetchAuth()
  } catch (error) {
    console.error(error)
  }
}

const formatDateOnRefresh = () => {
  formattedDate.value = formatDate(authorisation.value.expiry)
  todayDate.value = sendDate(new Date().toString())
}

const confirmDeactivationDate = () => {
  date.value = date.value || openDate.value
  sendDeactivate()
}

const sendDate = (date: string) => {
  return DateTime.fromISO(date).toFormat('yyyy-MM-dd')
}

const formatDate = (date: string) => {
  return DateTime.fromISO(date).toFormat('d MMMM yyyy')
}

onMounted(() => {
  formatDateOnRefresh()
})
</script>

<template>
  <div
    id="authorisation-section"
    class="authorisation-wrapper mb-4"
    role="alert"
  >
    <div
      class="bg-white p-3 p-md-4"
      :class="{ 'alert-danger': !swiftaidAuthorised }"
    >
      <h2 class="header-text">{{ $t('Dashboard.Settings.Auth.Heading') }}</h2>
      <transition name="fade" mode="out-in" appear>
        <div v-if="requestLoading">
          <div class="placeholder-text">
            <div class="subtitle"></div>
            <div class="paragraph"></div>
            <div class="button"></div>
          </div>
        </div>
        <div v-else>
          <div v-if="swiftaidAuthorised">
            <p class="fw-bold" aria-label="authorised Swiftaid text">
              {{ $t('Dashboard.Settings.Auth.IsAuth') }}
              <span v-if="authorisation.canBeExtended" class="text-warning">
                {{ formattedDate }}
              </span>
              <a v-if="!authorisation.canBeExtended">
                <span>{{ formattedDate }}</span>
              </a>
            </p>
            <p aria-label="Deactivate instruction">
              {{ $t('Dashboard.Settings.Auth.IsAuthSub') }}
            </p>
          </div>
          <div v-else>
            <p class="text-danger fw-bold" aria-label="Renewal notice">
              {{ $t('Dashboard.Settings.Auth.NotAuth') }}
            </p>
            <p aria-label="Renewal instruction">
              {{ $t('Dashboard.Settings.Auth.NotAuthSub') }}
            </p>
          </div>
          <div v-if="datePicked" id="date">
            <VueDatePicker
              v-model="date"
              model-type="yyyy-MM-dd"
              inline
              auto-apply
              :enable-time-picker="false"
              :min-date="new Date()"
              class="mb-4 mx-auto w-auto"
            />

            <div class="btn-toolbar">
              <button
                id="confirm"
                class="btn btn-sm btn-danger mt-2"
                :aria-label="$t('Dashboard.Settings.Auth.ButtonLabel')"
                @click="confirmDeactivationDate"
              >
                {{ $t('Buttons.ConfirmDate') }}
              </button>
              <button
                id="cancel"
                class="btn btn-sm btn-secondary mt-2"
                @click="datePicked = false"
              >
                {{ $t('Buttons.Cancel') }}
              </button>
            </div>
          </div>
          <div v-else id="authorisationButtons" class="btn-toolbar">
            <button
              v-if="!expiredDate && authorisation.expiry"
              id="deactivate"
              class="btn btn-sm btn-danger mt-2"
              :aria-label="$t('Buttons.DeactivateSwift')"
              @click="datePicked = true"
            >
              {{ $t('Buttons.DeactivateSwift') }}
            </button>
            <button
              v-if="authorisation.canBeExtended || !authorisation.expiry"
              id="renew"
              class="btn btn-sm btn-primary mt-2"
              :aria-label="$t('Buttons.RenewSwift')"
              @click="renewAuth"
            >
              {{ $t('Buttons.RenewSwift') }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.placeholder-text {
  .subtitle,
  .paragraph,
  .button {
    height: 24px;
    width: 100%;
    background: #eee;
    background: linear-gradient(to right, #eee 0%, #fff 50%, #eee 75%);
    background-size: 800px;
    margin-bottom: var(--spacer);
    animation: linear 5s placeHolderShimmer infinite;
  }

  .button {
    height: 38px;
    border-radius: 38px;
    width: 120px;
  }

  div:last-child {
    margin-bottom: 0;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
    opacity: 0;
  }
  50% {
    background-position: 800px 0;
    opacity: 1;
  }
  100% {
    background-position: 1600px 0;
    opacity: 0;
  }
}
</style>
