<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const onPageSettingsOrRenewPages = computed(() => {
  return route.name !== 'Settings' && route.name !== 'Renew'
})

const notSwiftaidAuthorised = computed(() => {
  return !store.getters.swiftaidAuthorised
})
</script>

<template>
  <router-link
    v-if="onPageSettingsOrRenewPages && notSwiftaidAuthorised"
    :to="{ name: 'Settings' }"
    class="notification-alert alert notification-alert--error"
  >
    <i
      class="fas fa-exclamation-circle fa-2x notification-alert__icon d-none d-sm-block"
    ></i>
    <span class="notification-alert__body">
      Swiftaid is not currently authorised to process Gift Aid on your behalf,
      please
      <strong>click here</strong>
      to renew authorisation.
    </span>
  </router-link>
</template>
