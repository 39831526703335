import { object, string, type InferType } from 'yup'

export const DEFAULT_DONOR_COUNTRY = 'United Kingdom'

export const GIBRALTAR_POSTCODE = 'GX111AA'

export enum LANDING_PAGE {
  NAME_ADDRESS = 0,
  QUESTIONS = 1,
  ADD_ACCOUNT = 2,
  DASHBOARD = 100,
}

/**
 * Schema for a donor information
 */
const validNameRegex = /^[ \p{L}'-]*$/u
export const donorInformationSchema = object({
  firstname: string()
    .trim()
    .required('First name is required')
    .max(200, 'First name is too long')
    .matches(validNameRegex, 'First name is invalid'),
  lastname: string()
    .trim()
    .required('Last name is required')
    .max(200, 'Last name is too long')
    .matches(validNameRegex, 'Last name is invalid'),
  addressLine1: string()
    .trim()
    .required('Address line 1 is required')
    .max(200, 'Address line 1 is too long'),
  addressLine2: string()
    .trim()
    .max(300, 'Address line 2 is too long')
    .optional(),
  city: string().trim().max(200, 'City is too long').optional(),
  county: string().trim().max(200, 'County is too long').optional(),
  country: string().trim().optional().default(DEFAULT_DONOR_COUNTRY),
  postcode: string()
    .when('country', {
      is: (country: string) => country === DEFAULT_DONOR_COUNTRY,
      then: (schema: any) =>
        schema.required('Required').min(4, 'Required').max(10, 'Too long'),
      otherwise: (schema: any) => schema.max(10, 'Too long'),
    })
    .trim()
    .uppercase(),
})
export type DonorInformation = InferType<typeof donorInformationSchema>

/**
 * Schema for any email input
 */
export type AddressFromLookUpService = {
  line_1?: string
  line_2?: string
  line_3?: string
  post_town?: string
  county?: string
  udprn?: string
}

/**
 * Schema for any email input
 */
const emailRegex =
  /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
export const emailSchema = object({
  email: string()
    .trim()
    .lowercase()
    .required('Email is required')
    .matches(emailRegex, 'Must be a valid email'),
})
export type Email = InferType<typeof emailSchema>

/**
 * Schema for the auth0 code input
 */
export const codeSchema = object({
  code: string().trim().required().length(6, 'Code must be 6 characters'),
})
export type Code = InferType<typeof codeSchema>

/**
 * Schema for a donor account
 */
export type Account = {
  id: string
  type: string
  isPrimary: boolean
  cssClass?: string
  logo?: string
  last4?: string
  expiryDate?: string
}

export enum LoginMethod {
  CODE = 'code',
  LINK = 'link',
}
