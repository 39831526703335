import store from '@/store'
import debug from '@/helpers/debug'
import { LANDING_PAGE } from '@/types'

import {
  SET_REQUEST_LOADING,
  SET_CALLBACK_LANDING_PAGE,
  TOGGLE_LOGIN_METHOD,
} from '@/store/mutation-types'

export const debugRouterGuard = (to: any, from: any) => {
  debug('[ROUTER GUARDS] debugRouterGuards', Date.now())
  return true
}

export const requiresUserToBeUnauthenticated = (to: any, from: any) => {
  debug('[ROUTER GUARDS] requiresUserToBeUnauthenticated', { to, from, store })

  if (import.meta.env.MODE === 'mockapi') {
    debug('[ROUTER GUARDS] Mock API is enabled')
    return true
  }

  // @ts-ignore
  if (store.state.auth.authenticated) {
    return _redirectUserToRequestedPage(to)
  }

  return true
}

export const requiresUserToBeAuthenticated = (to: any, from: any) => {
  debug('[ROUTER GUARDS] requiresUserToBeAuthenticated', { to, from, store })

  if (import.meta.env.MODE === 'mockapi') {
    return true
  }

  // @ts-ignore
  if (store.state.auth.authenticated) {
    return true
  }

  return { name: 'DONOR_FLOW_ManageAccountLogin' }
}

export const handleSignUpState = (to: any, from: any) => {
  debug('[ROUTER GUARDS] handleSignUpState', { to, from, store })

  if (import.meta.env.MODE === 'mockapi') {
    return true
  }

  // Send a user to the correct landing page
  // if not completed name & address.
  if (
    // @ts-ignore
    store.state.donor.userDetails.landingPage === LANDING_PAGE.NAME_ADDRESS &&
    to.name !== 'DONOR_FLOW_ManageAccountCreateDetails'
  ) {
    return { name: 'DONOR_FLOW_ManageAccountCreateDetails' }
  }

  // Send a user to the correct landing page
  // if not agreed to declaration.
  if (
    // @ts-ignore
    store.state.donor.userDetails.landingPage === LANDING_PAGE.QUESTIONS &&
    to.name !== 'DONOR_FLOW_ManageAccountCreateDeclaration'
  ) {
    return { name: 'DONOR_FLOW_ManageAccountCreateDeclaration' }
  }

  return true
}

export const setRequestLoadingFalse = (to: any, from: any) => {
  debug('[ROUTER GUARDS] setRequestLoadingFalse', { to, from })

  store.commit(SET_REQUEST_LOADING, false)

  return true
}

export const redirectOnAuthCallbackError = (to: any, from: any) => {
  debug('[ROUTER GUARDS] redirectOnAuthCallbackError', { to, from })

  if (to.hash.includes('error_description')) {
    debug('An authentication error occurred', to.hash)
    return { name: 'DONOR_FLOW_ManageAccountLogin' }
  }

  return true
}

export const storeRequestedPage = (to: any, from: any) => {
  debug('[ROUTER GUARDS] storeRequestedPage', { to, from })

  store.commit(SET_CALLBACK_LANDING_PAGE, to.name)

  return true
}

export const catchAuth0CallbackQueryParam = (to: any, from: any) => {
  debug('[ROUTER GUARDS] catchAuth0CallbackQueryParam', { to, from })

  if (to.query.callbacklandingpage) {
    store.commit(SET_CALLBACK_LANDING_PAGE, to.query.callbacklandingpage)
  }

  return true
}

export const checkLoginMethod = (to: any, from: any) => {
  if (to.query.loginmethod === 'link') {
    store.commit(TOGGLE_LOGIN_METHOD)
  }

  return true
}

const _redirectUserToRequestedPage = (to: any) => {
  // @ts-ignore
  const callbackLandingPage = store.state.auth.callbackLandingPage
  if (callbackLandingPage && to.name !== callbackLandingPage) {
    return { name: callbackLandingPage }
  } else {
    return { name: 'DONOR_FLOW_ManageAccountUpdate' }
  }
}
