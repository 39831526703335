<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { type Account } from '@/types'

const store = useStore()

interface AccountProps {
  account: Account
}

const props = defineProps<AccountProps>()

const accountTypeConstant = computed(
  () => store.state.accounts.accountTypeConstant
)
const accountToBeDeleted = computed(
  () => store.state.accounts.accountToBeDeleted
)
const isToBeDeletedClass = computed(() =>
  props.account.id === accountToBeDeleted.value
    ? 'linked-account--to-be-deleted'
    : ''
)
const isPhoneOrEmail = computed(
  () =>
    props.account.type === accountTypeConstant.value.email ||
    props.account.type === accountTypeConstant.value.phone
)
const isCard = computed(
  () => props.account.type === accountTypeConstant.value.card
)
const isPar = computed(
  () => props.account.type === accountTypeConstant.value.par
)
</script>

<template>
  <div
    :data-id="account.id"
    class="linked-account px-0"
    :class="[account.cssClass, isToBeDeletedClass]"
  >
    <div class="linked-account__icon linked-account__icon">
      <img
        class="linked-account__image"
        alt="Account icon"
        :src="account.logo"
      />
    </div>
    <div class="linked-account__info">
      <div class="linked-account__ref mb-0">
        <span v-if="isCard || isPar">
          <span class="d-none d-sm-inline">**** **** ****</span>
          <span class="d-inline d-sm-none small">****</span>
          <span>{{ account.last4 }}</span>
        </span>
        <span v-if="isPhoneOrEmail">
          {{ account.id }}
        </span>
      </div>
      <div v-if="account.expiryDate" class="linked-account__exp">
        {{ account.expiryDate }}
      </div>
    </div>
    <div class="linked-account__actions">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.linked-account {
  $this: &;
  $smallPadding: 0.75rem;
  $largePadding: 1.25rem;

  background: var(--white);
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  width: 100%;

  &--unlinked,
  &--expired {
    #{$this}__exp {
      color: var(--theme-red);
      font-weight: bold;
    }
  }

  &--scheme-visa {
    #{$this}__icon {
      background-color: #162d6d;
    }

    #{$this}__image {
      @media (min-width: 768px) {
        width: 60px;
      }
    }
  }

  &--scheme-mastercard {
    #{$this}__icon {
      background-color: #13224b;
    }
  }

  &--scheme-amex {
    #{$this}__icon {
      background-color: #016fd0;
    }

    #{$this}__image {
      @media (min-width: 768px) {
        width: 70px;
      }
    }
  }

  &--email {
    #{$this}__image {
      @media (min-width: 768px) {
        width: 40px;
      }
    }
  }

  &--scheme-error {
    #{$this}__icon {
      background-color: var(--theme-red);
    }
  }

  &--to-be-deleted {
    box-shadow:
      0 0 35px -20px var(--theme-red),
      0 0 0 1px rgba(var(--theme-red-rgb), 0.25) inset !important;
    background-color: mix(#e96b7a, #fff, 10%);
    color: var(--theme-red);

    #{$this}__icon {
      box-shadow: 0 0 0 1px var(--theme-red) inset;
      background: var(--theme-red);
    }
  }

  &__icon {
    background-color: var(--black);
    width: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 5px 0 0 5px;
    transition: background 0.2s ease-in-out;

    @media (min-width: 768px) {
      width: 100px;
      min-height: 100px;
    }
  }

  &__image {
    width: 30px;
    height: auto;

    @media (min-width: 768px) {
      width: 50px;
    }
  }

  &__info {
    padding: $smallPadding;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 65%;

    @media (min-width: 768px) {
      padding: $largePadding;
    }
  }

  &__ref {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    @media (min-width: 768px) {
      max-width: none;
      font-size: 1.25rem;
    }
  }

  &__actions {
    padding: $smallPadding;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      padding: $largePadding;
    }
  }

  $menu-color: lighten(#ced4da, 12%);
  &__actions--open &__menu-button {
    background-color: $menu-color;
  }

  &__menu-button {
    color: var(--black);
    display: flex;
    padding: 0 0 0 20px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    height: 100%;
    width: 100%;
    align-items: center;

    .account__menu-bg {
      position: relative;
      padding: 0 10px;
    }

    &:hover .account__menu-bg {
      background-color: $menu-color;
    }
  }

  &__menu {
    position: absolute;
    z-index: 2;
    top: 23px;
    right: 0;
    width: 140px;
    padding: 4px;
    background: $menu-color;
    border-radius: 2px;
    box-shadow: 0 6px 20px -10px darken(#ced4da, 12%);
  }

  &__open-menu-actions {
    text-align: right;
  }

  &__open-menu-items {
    font-size: 14px;
    letter-spacing: 0.3px;
    font-weight: 600;

    a {
      color: var(--black);
      display: block;
      padding: 6px;
      border-radius: 3px;

      &:hover {
        background-color: darken($menu-color, 5%);
      }
    }
  }
}
</style>
