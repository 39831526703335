import auth0 from '../../services/auth0'
import store from '../../store'
import debug from '../../helpers/debug'
import { AUTH_CONFIG } from './auth0-variables'

const renewAndStoreToken = async () => {
  return new Promise((resolve, reject) => {
    auth0.renewAuth(
      {
        audience: AUTH_CONFIG.audience,
        scope: AUTH_CONFIG.scope,
      },
      (err, result) => {
        if (err) {
          debug('debug: renewAuth error', err)
          reject(err)
        } else {
          if (result !== null) {
            debug('debug: renewAuth result', result)
            store.dispatch('storeToken', result).then(() => {
              resolve(result)
            })
          }
        }
      }
    )
  })
}

const renewExpiringToken = async () => {
  const expTime = localStorage.getItem('expires_at') as unknown as number
  const secondsLeft = expTime / 1000 - Date.now() / 1000

  debug('debug: secondsLeft', secondsLeft)

  if (secondsLeft <= 0) {
    debug('debug: renewing token')
    await renewAndStoreToken()
  }
}

export default renewExpiringToken
