<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import BlankLayout from '@/layouts/BlankLayout.vue'
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import ErrorBar from '@/components/generic/ErrorBar.vue'
import ChatbotClearance from '@/components/generic/ChatbotClearance.vue'
import debug from '@/helpers/debug'
import useCookieConsent from '@/composables/useCookieConsent'
import { SET_APP_LOADING } from '@/store/mutation-types'
import { LANDING_PAGE } from '@/types'

const authResult = ref(null)

const store = useStore()
const route = useRoute()
const router = useRouter()
useCookieConsent()

const appLoading = computed(() => store.state.appLoading)
const callbackLandingPage = computed(() => store.state.auth.callbackLandingPage)
const layout = computed(() => {
  if (route.meta.layout === 'DashboardLayout') {
    return DashboardLayout
  }

  return BlankLayout
})

onBeforeMount(async () => {
  await router.isReady()

  if (import.meta.env.MODE === 'mockapi' || route.name === 'AccountVerify') {
    store.commit(SET_APP_LOADING, false)
    return
  }

  const authed = await checkAuth0Session()

  if (authed) {
    await fetchUserAndRedirect()
  }

  store.commit(SET_APP_LOADING, false)
})

onMounted(() => {
  if (route.query.refid) {
    store.dispatch('setReferralLink', route.query.refid)
  }
})

const redirectToRoute = (name: string) => {
  router.replace({ name })
}

const handleLoginRequiredError = (error: any) => {
  if (error?.code === 'login_required') {
    redirectToRoute('DONOR_FLOW_ManageAccountLogin')
  }
}

const handleUserNotFound = (error: any) => {
  if (error?.response?.status === 404) {
    redirectToRoute('DONOR_FLOW_ManageAccountUserNotFound')
  }
}

const checkAuth0Session = async (): Promise<Boolean> => {
  try {
    authResult.value = await store.dispatch('auth0CheckSession')

    if (!authResult.value) {
      throw new Error('No result from Auth0')
    }

    return true
  } catch (error) {
    debug('Error in App#checkAuth0Session', { cause: error })
    handleLoginRequiredError(error)
    return false
  }
}

const fetchUserAndRedirect = async (): Promise<void> => {
  let landingPage
  try {
    await store.dispatch('storeToken', authResult.value)
    const user = await store.dispatch('fetchUser')
    landingPage = user.data.landingPage
  } catch (error) {
    debug('Error in App#fetchUserAndRedirect', { cause: error })
    handleUserNotFound(error)
    return
  }

  // Handle dashboard requests
  if (route.name === 'Dashboard') {
    return
  }

  // Prevents people wanting to close account having
  // to add details and declarations before closing
  if (callbackLandingPage.value === 'DONOR_FLOW_ManageAccountClose') {
    redirectToRoute('DONOR_FLOW_ManageAccountClose')
    return
  }

  // Send a user to the correct landing page
  // if not completed name & address
  if (landingPage === LANDING_PAGE.NAME_ADDRESS) {
    redirectToRoute('DONOR_FLOW_ManageAccountCreateDetails')
    return
  }
  // Send a user to the correct landing page
  // if not agreed to declaration
  if (landingPage === LANDING_PAGE.QUESTIONS) {
    redirectToRoute('DONOR_FLOW_ManageAccountCreateDeclaration')
    return
  }

  // Handle requested landing page
  if (callbackLandingPage.value) {
    redirectToRoute(callbackLandingPage.value)
  } else {
    redirectToRoute('DONOR_FLOW_ManageAccountUpdate')
  }
}
</script>

<template>
  <ErrorBar />
  <div v-if="appLoading" class="sa-loader">
    <div class="sa-loader__ring"></div>
  </div>
  <div v-else>
    <transition name="fade" mode="out-in" appear>
      <component :is="layout" />
    </transition>
    <ChatbotClearance />
  </div>
</template>

<style lang="scss">
@import './sass/main';
</style>
