<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()

defineProps({
  link: {
    type: String,
    required: true,
  },
  ariaLabel: {
    type: String,
    default: 'Notification',
  },
})
</script>

<template>
  <router-link
    :to="{ name: link }"
    class="notification-alert alert notification-alert--transparent"
    :aria-label="ariaLabel"
    @click="router.go(-1)"
  >
    <div class="d-flex align-items-center">
      <div class="notification-alert__icon d-none d-sm-block">
        <i class="fas fa-exclamation-triangle notification-alert__cc-icon" />
      </div>
      <span class="notification-alert__body">
        <slot />
      </span>
    </div>
  </router-link>
</template>
