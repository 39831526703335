<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { emailSchema } from '@/types'
import FieldError from '@/components/views/manage-account/FieldError.vue'
import { LoginMethod } from '@/types'

const props = defineProps({
  loginMethod: {
    type: String,
    required: false,
    default: LoginMethod.CODE,
  },
})

const store = useStore()

const validationSchema = toTypedSchema(emailSchema)
const { handleSubmit, errors, defineField } = useForm({
  validationSchema,
})
const [email] = defineField('email')

const btnLoading = computed(() => store.state.auth.btnLoading)
const referralEmail = computed(() => store.state.referralEmail)

onMounted(() => {
  addReferralEmail()
})

const sendEmail = handleSubmit((values) => {
  store.dispatch('sendLoginEmail', {
    email: values.email,
    send: props.loginMethod,
  })
})

const addReferralEmail = () => {
  if (referralEmail.value) {
    email.value = referralEmail.value
  }
}
</script>

<template>
  <form class="text-left" novalidate @submit.prevent="sendEmail">
    <label
      for="email"
      class="mb-2 d-block text-start"
      v-text="$t('ManageAccount.Login.EmailForm.FieldLabel')"
    ></label>

    <input
      id="email"
      v-model="email"
      class="form-control form-control-lg"
      spellcheck="false"
      type="email"
      :class="{ 'is-invalid': errors.email?.length }"
      name="email"
      autocomplete="email"
      :placeholder="$t('ManageAccount.Login.EmailForm.FieldPlaceholder')"
    />

    <FieldError :error="errors.email" class="error text-start" />

    <div class="mt-4 d-flex justify-content-center">
      <button
        type="submit"
        class="sa-btn sa-btn-primary-solid sa-btn-lg sa-btn-block"
        :disabled="btnLoading"
      >
        <div v-if="btnLoading" class="d-flex align-items-center">
          <div class="spinner sa-btn-spinner me-2" role="alert"></div>
          <div
            v-text="$t('ManageAccount.Login.EmailForm.Button.LoadingText')"
          ></div>
        </div>
        <div
          v-else
          v-text="$t('ManageAccount.Login.EmailForm.Button.Text')"
        ></div>
      </button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
label[for='email'],
#email,
.error {
  max-width: 300px;
  margin: auto;
}

button[type='submit'] {
  max-width: 220px;
  margin: auto;
}
</style>
