export default {
  LoginRegister: {
    Index: {
      ImageAlt: 'Giftaid it logo',
      Heading: 'Make more of a difference with Swiftaid',
      Cta: 'Register for free',
    },
    Email: {
      DefaultScreenTitle: 'Enter your email to get started',
      InvalidEmailScreenTitle: 'Whoops! Please try again',
      DefaultSwiftySpeechText:
        'Gift Aid is a tax relief that lets UK charities claim an extra 25% on every eligible donation made by a UK taxpayer',
      InvalidEmailSwiftySpeechText:
        "Whoops! That email doesn't look quite right! Please try again",
      Label: 'Your personal email address',
      Button: 'Continue',
      ConfirmStatement: "By continuing, you are confirming you're a",
      UkTaxpayerLink: 'UK taxpayer',
      AgreeStatement: 'and agree to Swiftaid’s',
      Terms: 'Terms',
      And: 'and',
      Privacy: 'Privacy Policy',
      NoSwiftaidAccount: "Don't have a Swiftaid account?",
      SignUpHere: 'Sign up here',
      ThankYou: "Thank you! I've sent an email to",
      Header: 'Please check your email to continue',
      UseDiffEmail: 'Use a different email',
      CheckYourJunk:
        "Can't find the email? Please check your junk or spam folder 📁",
    },
    Details: {
      Header: 'Your name & address',
      SubHeader:
        "Please enter these correctly, otherwise we won't be able to Gift Aid your card donations 💳 ❤️",
      FirstName: 'First Name',
      FirstNamePlaceholder: 'Clark',
      LastName: 'Last Name',
      LastNamePlaceholder: 'Kent',
      Country: 'Country',
      Postcode: 'Postcode',
      ZipCodePostalCode: 'Zip/postal code',
      PostcodePlaceholder: 'SW1A 1AA',
      SelectAddress: 'Select your address',
      PostcodeNotFound:
        'This postcode could not be found, please enter your address manually below.',
      AddressLookupFailure:
        'An error occurred, please try again or enter your address manually below.',
      CannotFindMyAddress: 'I cannot find my address in the list',
      AddressLine1: 'Address line 1',
      AddressLine1Placeholder: 'Enter line 1 of your address',
      AddressLine2: 'Address line 2',
      AddressLine2Placeholder: 'Enter line 2 of your address',
      Town: 'City/Town',
      TownPlaceholder: 'Enter your city or town',
      County: 'County',
      CountyPlaceholder: 'Enter your county',
      StateProvinceCounty: 'State/province/county',
      StateProvinceCountyPlaceholder: 'Enter your state, province or county',
      GenericFieldError: 'Whoops, there is an error above',
      ButtonLabels: {
        Postcode: {
          Active: 'Press enter to find your address from the postcode',
          Disabled: 'This button is disabled until you enter your postcode',
        },
        Details: {
          Disabled:
            'This button is disabled as your address is currently incomplete',
        },
      },
    },
    Declaration: {
      Header:
        'Please read and confirm that you agree with the following 5 statements',
      DeclarationStatements: [
        {
          Icon: '📆',
          Title: 'Gift Aid Authorisation',
          Text: 'I authorise Swiftaid to make Gift Aid declarations on my behalf during this tax year.',
        },
        {
          Icon: '🇬🇧',
          Title: 'UK taxpayer status',
          Text: 'I am a UK taxpayer and wish to add Gift Aid automatically to donations I make to supported charities using Swiftaid.',
        },
        {
          Icon: '💸',
          Title: 'Paying enough tax',
          Text: 'If I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all of my donations in that tax year it is my responsibility to deactivate Swiftaid and pay any difference to HMRC.',
        },
        {
          Icon: '👤',
          Title: 'Keeping up to date',
          Text: 'I will keep all of my details including name and address up to date by logging in to my Swiftaid account.',
        },
        {
          Icon: '🗂️',
          Title: 'Self assessment',
          Text: 'If I pay Income Tax at the higher or additional rate and want to receive the additional tax relief due, I will include all of my Gift Aid donations on my Self-Assessment tax return or ask HM Revenue and Customs to adjust my tax code.',
        },
      ],
      Terms:
        'I agree with the above statements and have read and agree with Swiftaid’s {terms} and {privacy}.',
      ConfirmButton:
        'A button to confirm you have read and agree to these statements',
    },
    HowSwiftaidWorks: {
      LinkText: 'Learn how Swiftaid works',
      Header: 'How Swiftaid works',
      Para1:
        'Every year UK Charities miss out on over half a billion pounds in unclaimed Gift Aid, with your help we can fix this!',
      Para2:
        'Swiftaid works as a Gift Aid intermediary, helping charities claim Gift Aid on donations.',
      Para3:
        'As a UK taxpayer, you can add 25% to your contactless donations by signing up and linking your card to Swiftaid for free.',
      Para4:
        'Any charity that uses Swiftaid will then automatically receive Gift Aid every time you tap to donate 💳 ❤️',
      CloseButton: 'Close',
    },
    Footer: {
      Streeva: 'Streeva Ltd',
      Statement:
        'Registered in England & Wales (No. 09451226). Registered address: Carsize Barn, Cornwall, TR27 5EX, UK.',
      Terms: 'Terms',
      Privacy: 'Privacy',
      TermsModal: {
        Header: 'Terms & Conditions',
      },
      PrivacyModal: {
        Header: 'Privacy Policy',
      },
    },
    LoginHeading: 'Log in with your email',
    LoginLabel: 'Enter your email to continue',
  },
  Dashboard: {
    Donations: {
      Image: {
        HeartOverHand: 'An image of a heart floating above a hand,',
      },
      Not: {
        Title: 'Your donations will appear here',
        Sub: "You'll receive an email each time a new one arrives. Some donations can take up to 3 days to appear.",
      },
      SelectTaxYearLabel: 'Tax Year',
      Donated: 'Donated',
      GiftAid: 'Gift Aid',
      OrderBy: 'Order by',
      MostRecent: 'most recent',
      LeastRecent: 'least recent',
      Table: {
        EmptyMessage:
          'There are no donations to display for the selected tax year',
        Headings: {
          Donor: 'Your Donations',
        },
      },
      Summary: {
        Header: {
          Donated: 'Donated to charity',
          GiftAid: 'Added Gift Aid',
        },
      },
      DonationStatus: {
        Processing: 'Processing',
        Added: 'Added',
        OnHold: 'On hold',
        Cancelled: 'Cancelled',
        PendingCancellation: 'Pending cancellation',
      },
    },
    Statements: {
      Image: {
        Statement: 'An image of a mock statement,',
      },
      NotTitle: 'Annual statements',
      NotSub:
        "You'll receive a new statement at the end of each tax year. Use these to help with your tax return 👍",
      TableHeadings: {
        StatementYear: 'Statement year',
        TotalDonated: 'Total donated',
        GiftAid: 'Gift Aid',
      },
      FetchingStatements: 'Fetching statements',
      PrintButton: 'Print',
      Print: {
        Statement: 'Gift Aid statement',
        Donated: 'Total Donated',
        GiftAid: 'Total Gift Aid',
        Donor: 'Donor',
        DonorIntermediary: 'Donor Intermediary',
        Issued: 'Issued',
        Streeva: 'Streeva Ltd',
        Ref: 'Ref No',
        Summary:
          'This statement shows the value of donations made by you totalling £{totalDonated} over the tax year {taxYear} with a maximum amount of £{totalGiftAid} claimable as gift aid by charities.',
        Warning:
          'The total donated shown does not include anything given through other donor intermediaries on your behalf, or given by you directly to a charity.',
        Warning2:
          'If you paid less income tax and capital gains tax in this tax year than the amount of gift aid claimed by charities on all your donations, it is your responsibility to pay any difference.',
      },
    },
    Accounts: {
      ScreenTitle: 'Linked accounts',
      ScreenSubTitle:
        'Here you can review and link new accounts to automate Gift Aid on all your methods of giving.',
      ScreenChargeNote:
        '🔒 Information you link here will only be used to find donations that qualify for Gift Aid – you will never be charged.',
      CardSection: {
        Title: 'Linked cards',
        SubTitle: 'For automating Gift Aid on your card donations.',
      },
      EmailSection: {
        Title: 'Linked emails',
        SubTitle: 'For automating Gift Aid on your online giving donations.',
      },
      PhoneSection: {
        Title: 'Linked mobile numbers',
        SubTitle: 'For automating Gift Aid on your text giving donations.',
      },
      Cancellation: {
        Box: {
          Header: 'Are you sure?',
          Sub: 'Please confirm you want to remove your {account}',
          Label: 'Type DELETE to confirm',
        },
      },
      DeleteAccountAriaLabel:
        'To close this account type delete in capital letters and press enter',
      DeletePlaceholder: 'DELETE',
      AccountNotFoundError:
        'There was a problem finding this account, please try again',
    },
    Settings: {
      Auth: {
        Heading: 'Authorisation',
        IsAuth:
          'Swiftaid is authorised to process Gift Aid on your behalf until',
        IsAuthSub:
          'Click the button below to deactivate Swiftaid sooner. You can reactivate at any time.',
        NotAuth:
          'Swiftaid is not currently authorised to process Gift Aid on your behalf',
        NotAuthSub:
          'Click the button below to reactivate Swiftaid and automate Gift Aid',
        ButtonLabel:
          'By pressing this button authorisation to claim GiftAid will be cancelled from today',
      },
      RenewAuth: {
        Heading: 'Are the following details correct?',
        Agreement:
          'Please read through the following 5 statements and agree to reactivate Swiftaid.',
      },
      Cancellation: {
        Heading: 'Delete account',
        Text: 'Click the button below to close your Swiftaid account',
        Box: {
          Header: 'Are you sure?',
          Sub: 'Deleting your account will remove access to Swiftaid and will no longer process Gift Aid on your behalf.',
          Label: 'Type DELETE to confirm',
        },
      },
      DeleteSwiftaidAccountAriaLabel:
        'To close this account type delete in capital letters and press enter',
      DeletePlaceholder: 'DELETE',
      Email: {
        Heading: 'Account email address',
      },
      Personal: {
        Heading: 'Personal information',
        Details: 'Personal details',
        FullName: 'Full name',
        Address: 'Address',
      },
    },
  },
  ManageAccount: {
    CloseAccount: {
      Header: 'Are you sure you want to close your Swiftaid account?',
      Confirm: 'Yes',
      Cancel: 'No',
    },
    CloseAccountConfirm: {
      Header: 'All done 👍',
      Para1: 'Your Swiftaid account has been closed.',
      Para2:
        'Please note: You may receive a Gift Aid statement at the end of the tax year, which we are legally obliged to send, if you made 2 or more donations totalling over £20. For full details on how your data is used and the reason behind it, please refer to our',
      Para2LinkText: 'Privacy Policy.',
      Button: 'Done',
    },
    SuspendAccountCalendar: {
      Header:
        'When would you like Swiftaid to stop claiming Gift Aid on your behalf?',
      Paragraph:
        'We’ll freeze your Swiftaid account on the date you select above.',
      Button: {
        Text: 'Confirm',
        LoadingText: 'Updating',
      },
      BackButton: 'Back',
    },
    SuspendAccountConfirm: {
      Header: 'All done 👍',
      Para1: 'Thank you, we’ll go ahead and freeze your Swiftaid account.',
      Para2:
        'We’ll activate your account again the next time you donate and add Gift Aid through a donation platform using Swiftaid.',
      Button: 'Done',
    },
    UpdateAccount: {
      Header: 'Your Swiftaid account',
      EditDetailsButton: 'Edit',
      AccountsMenu: {
        MakePrimary: 'Set as primary',
        RemoveButton: 'Delete',
      },
      AddAccount: {
        AddButton: 'Add',
        AddButtonLoading: 'Adding...',
        SuccessMessagePara1:
          'A verification link has been sent to {email}. Please click the link provided in that email to add it to your Swiftaid account.',
        SuccessMessagePara2:
          "If you don't see the email immediately, it may take a few minutes to arrive. Don't forget to check your junk or spam folder if it's not in your inbox.",
        FailureMessage:
          'There was a problem adding this account, please try again or contact support for help.',
      },
    },
    Login: {
      Start: {
        Header: 'Enter your email',
        Paragraph:
          'Please enter the email associated with your Swiftaid account below. We’ll send a code that can be used to continue.',
        MagicLinkHeader: 'Let’s try something else',
        MagicLinkParagraph:
          'Please enter the email associated with your Swiftaid account below. We’ll send a unique link that can be used to continue.',
        TroubleSigningIn: 'Having trouble signing in?',
        TroubleSigningInLinkText: 'Try a different method here',
      },
      End: {
        Header: "Don't close this page",
        Paragraph:
          'We have sent a login code to {email}, please enter it below to continue.',
        MagicLinkHeader: 'Check your email to continue',
        MagicLinkParagraph: 'We have sent a link to login to {email}',
        Note: "If you don't see the email immediately, it may take a few minutes to arrive. Don't forget to check your junk or spam folder if it's not in your inbox. 📁",
        ResetLink: 'Reset',
      },
      EmailForm: {
        FieldLabel: 'Your email',
        FieldPlaceholder: 'Email',
        Button: {
          Text: 'Confirm',
          LoadingText: 'Sending',
        },
      },
      CodeForm: {
        FieldLabel: 'Enter code here:',
        FieldPlaceholder: 'Code',
        Button: {
          Text: 'Continue',
          LoadingText: 'Sending',
        },
      },
    },
    UserNotFound: {
      Header: 'Whoops',
      Paragraph: "We couldn't find an account for the email address",
      ParagraphWithEmail:
        "We couldn't find an account for the email address entered",
      Instruction:
        'Please make sure you are using the same email we use to send you messages and notifications',
      Button: 'Try a different email',
      HelpLink: 'Need help? Let us know here',
    },
    InfoLinks: {
      Title: 'Looking for something else?',
      SuspendAccountLink: {
        Title: 'Freeze your account',
        Description:
          'Keep your account but stop Swiftaid claiming Gift Aid on your donations.',
      },
      CloseAccountLink: {
        Title: 'Close your account',
      },
      SomethingElseLink: {
        Title: 'Something else',
      },
    },
  },
  Accessibility: {
    Button: {
      Loading: 'The action is loading',
      Continue: 'Press enter to continue',
    },
    ErrorOccurred: 'An error has occurred, please try again',
  },
  Buttons: {
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    DeactivateSwift: 'Deactivate Swiftaid',
    RenewSwift: 'Renew authorisation',
    ConfirmDate: 'Confirm date',
    No: 'No',
    Yes: 'Yes',
    CloseAccount: 'Close account',
    Edit: 'Edit personal details',
    Continue: 'Continue',
    FindAddress: 'Find address',
    Save: 'Save',
    Agree: 'I agree',
    Logout: 'Log out',
    ResendLink: 'Resend link',
    ResendCode: 'Resend code',
    Resending: 'Resending...',
  },
  Menu: {
    Accounts: 'Linked accounts',
    Dashboard: 'Donations',
    Statements: 'Statements',
    Settings: 'Settings',
  },
  AxiosError: {
    BadRequest: 'Bad request, please try again',
    Unauthorised: 'You are unauthorised, please login again',
    Network: 'Unable to connect to server, please try again',
    NotFound: 'Sorry could not find this entry, please try again',
    Forbidden: 'Access denied please try again',
    Conflict: 'There was a conflict please try again',
    Server: 'A server error has occurred, please try again',
    ServiceUnavailable: 'Service unavailable, please check back soon.',
    Default:
      'An error has occurred, please try again or contact support for help',
  },
  AccountVerify: {
    Header: 'Verify your account',
    Verifying: 'Verifying account, please wait',
    Success:
      'Verification successful... you can go ahead and close this window',
    Failure: 'Verification has failed',
    ErrorBadRequest: 'The confirmation code is invalid',
    ErrorUnauthorized:
      'The verification code is not valid or has expired, please try again',
    ErrorNotFound: 'Something went wrong, please try again',
    ErrorConflict: 'Could not add account',
    ErrorServer: 'A server error occurred',
    Support: 'If you need help, please contact support',
  },
}
