<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import swiftyCheck from '@/assets/images/swifty/swifty-check.svg'
import Header from '@/components/views/manage-account/Header.vue'
import AnimatedEllipsis from '@/components/generic/AnimatedEllipsis.vue'

const VerificationStatus = {
  Verifying: 'verifying',
  Success: 'success',
  Failure: 'failure',
}

const store = useStore()
const route = useRoute()

const status = ref(VerificationStatus.Verifying)
const errorMessage = ref('')

const verifying = computed(() => status.value === VerificationStatus.Verifying)
const success = computed(() => status.value === VerificationStatus.Success)
const failure = computed(() => status.value === VerificationStatus.Failure)

const verifyAccount = async () => {
  if (route.query.confirmation_code === undefined) {
    status.value = VerificationStatus.Failure
    errorMessage.value = 'AccountVerify.ErrorNotFound'
    return
  }

  try {
    await store.dispatch(
      'postAccountVerificationConfirmationCode',
      route.query.confirmation_code
    )

    status.value = VerificationStatus.Success
  } catch (error: any) {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          errorMessage.value = 'AccountVerify.ErrorBadRequest'
          break
        case 401:
          errorMessage.value = 'AccountVerify.ErrorUnauthorized'
          break
        case 404:
          errorMessage.value = 'AccountVerify.ErrorNotFound'
          break
        case 409:
          errorMessage.value = 'AccountVerify.ErrorConflict'
          break
        default:
          errorMessage.value = 'AccountVerify.ErrorServer'
          break
      }
    } else {
      errorMessage.value = 'AccountVerify.ErrorServer'
    }

    status.value = VerificationStatus.Failure
  }
}

onMounted(() => {
  verifyAccount()
})
</script>

<template>
  <div class="container">
    <Header
      :img-src="swiftyCheck"
      img-alt="Swifty reading"
      :header="$t('AccountVerify.Header')"
    />
    <div class="row mb-4">
      <div class="col text-center fs-5">
        <div v-if="verifying">
          {{ $t('AccountVerify.Verifying') }}
          <AnimatedEllipsis />
        </div>

        <div v-if="success">{{ $t('AccountVerify.Success') }}</div>

        <div v-if="failure">
          <p class="fs-5">
            <strong>{{ $t('AccountVerify.Failure') }}</strong>
          </p>

          <p>{{ $t(errorMessage) }}</p>

          <p>
            <strong>{{ $t('AccountVerify.Support') }}</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
