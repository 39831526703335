import {
  TOGGLE_SIDEBAR,
  SET_LOADING,
  GLOBAL_ERROR,
  SET_PRINT_DATA,
  SET_TERMS_MODAL_VISIBILITY,
  SET_PRIVACY_MODAL_VISIBILITY,
} from '@/store/mutation-types'
import type { Commit } from 'vuex'

export default {
  toggleSidebar({ commit }: { commit: Commit }, payload: any) {
    commit(TOGGLE_SIDEBAR, payload)
  },
  setLoading({ commit }: { commit: Commit }, payload: any) {
    commit(SET_LOADING, payload)
  },
  setTermsModalVisibility({ commit }: { commit: Commit }, payload: any) {
    commit(SET_TERMS_MODAL_VISIBILITY, payload)
  },
  setPrivacyModalVisibility({ commit }: { commit: Commit }, payload: any) {
    commit(SET_PRIVACY_MODAL_VISIBILITY, payload)
  },
  setGlobalError({ commit }: { commit: Commit }, payload: any) {
    commit(GLOBAL_ERROR, payload)
  },
  async printStatement({ commit }: { commit: Commit }, payload: any) {
    await commit(SET_PRINT_DATA, payload)

    const originalDocumentTitle = document.title
    document.title = `Swiftaid Gift Aid Statement ${payload.period}`

    window.print()

    document.title = originalDocumentTitle
  },
}
