<script setup lang="ts">
import { useStore } from 'vuex'

const store = useStore()

const { emailAddress } = store.state.donor.userDetails
</script>

<template>
  <div v-if="emailAddress" id="email-section">
    <div class="bg-white pt-3 px-3 pt-md-4 px-md-4">
      <h2 class="header-text">
        {{ $t('Dashboard.Settings.Email.Heading') }}
      </h2>
      <p class="mb-0">{{ emailAddress }}</p>
    </div>
  </div>
</template>
