import type { Commit, Dispatch } from 'vuex'
import api from '@/store/api/donor'
import { sendExceptionToAnalytics } from '@/services/googleAnalytics/errorHandling'
import {
  SET_LOADING,
  SET_REQUEST_LOADING,
  GLOBAL_ERROR,
  SET_USER_STATEMENTS,
  SET_DONOR_DONATIONS,
  SET_AUTHORISATION,
  CLEAR_SWIFTAID_AUTHORISATION,
  SET_SWIFTAID_AUTHORISED,
  SET_USER,
  SET_POSTED_USER,
  SET_REFERRAL_LINK,
  SET_AUTH,
  RESET_REGISTRATION_STATE,
  SET_TOKEN,
} from '@/store/mutation-types'

export default {
  // NOTE: authorisation
  async fetchAuth({ commit }: { commit: Commit }) {
    try {
      const response = await api.fetchAuth()
      if (typeof response.data === 'string') {
        // Response is a no active authorisation message string
        // e.g. 'No active authorisation found for user'
        commit(SET_SWIFTAID_AUTHORISED, false)
        commit(SET_AUTHORISATION, {})
      } else {
        // Response is an object
        // e.g. {"authorised":"2020-02-28","expiry":"2020-04-05","canBeExtended":false}
        commit(SET_SWIFTAID_AUTHORISED, true)
        commit(SET_AUTHORISATION, response.data)
      }
      return response
    } catch (error) {
      sendExceptionToAnalytics(
        `/src/store/modules/donor.js#fetchAuth: ${error}`
      )
    } finally {
      commit(SET_REQUEST_LOADING, false)
    }
  },
  async postAuth({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
    try {
      commit(SET_REQUEST_LOADING, true)
      const response = await api.postAuth()
      dispatch('fetchAuth')
      return response
    } catch (error: any) {
      sendExceptionToAnalytics(`/src/store/modules/donor.js#postAuth: ${error}`)
      if (error.response && error.response.data) {
        commit(GLOBAL_ERROR, error.response.data)
      }
    } finally {
      commit(SET_REQUEST_LOADING, false)
    }
  },
  async deleteAuth({ commit }: { commit: Commit }, payload: any) {
    try {
      commit(SET_LOADING, true)
      const response = await api.deleteAuth(payload)
      return response
    } catch (error) {
      sendExceptionToAnalytics(
        `/src/store/modules/donor.js#deleteAuth: ${error}`
      )
    } finally {
      commit(SET_LOADING, false)
    }
  },
  // NOTE: user
  async fetchUser({
    commit,
    dispatch,
    state,
  }: {
    commit: Commit
    dispatch: Dispatch
    state: any
  }) {
    try {
      commit(SET_REQUEST_LOADING, true)
      const response = await api.fetchUser(state.referralLink)
      commit(SET_USER, response.data)
      dispatch('accounts/addAccounts', response.data.accounts)
      return response
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        commit(GLOBAL_ERROR, null)
        commit(SET_AUTH, false)
        commit(SET_TOKEN, null)
        commit(RESET_REGISTRATION_STATE)
        throw error
      }
      
      sendExceptionToAnalytics(
        `/src/store/modules/donor.js#fetchUser: ${error}`
      )
    } finally {
      commit(SET_REQUEST_LOADING, false)
    }
  },
  async postDonorInformation({ commit }: { commit: Commit }, payload: any) {
    try {
      commit(SET_POSTED_USER, payload)
      return await api.postDonorInformation(payload)
    } catch (error) {
      sendExceptionToAnalytics(
        `/src/store/modules/donor.js#postDonorInformation: ${error}`
      )
    }
  },
  async fetchUserStatements({ commit }: { commit: Commit }) {
    try {
      const response = await api.fetchUserStatements()
      commit(SET_USER_STATEMENTS, response.data)
      return response
    } catch (error) {
      sendExceptionToAnalytics(
        `/src/store/modules/donor.js#fetchUserStatements: ${error}`
      )
    }
  },
  async deleteSwiftAidAccount({ commit }: { commit: Commit }) {
    try {
      return await api.deleteSwiftAidAccount()
    } catch (error: any) {
      commit(SET_REQUEST_LOADING, false)
      if (error.response && error.response.status === 404) {
        commit(GLOBAL_ERROR, 'error.delete.notFound')
      }
      sendExceptionToAnalytics(
        `/src/store/modules/donor.js#deleteSwiftAidAccount: ${error}`
      )
    }
  },
  // NOTE: donations
  async fetchDonorDonations({ commit }: { commit: Commit }) {
    try {
      const response = await api.fetchDonorDonations()
      commit(SET_DONOR_DONATIONS, response.data)
      return response
    } catch (error) {
      sendExceptionToAnalytics(
        `/src/store/modules/donor.js#fetchDonorDonations: ${error}`
      )
    }
  },
  setReferralLink({ commit }: { commit: Commit }, payload: any) {
    commit(SET_REFERRAL_LINK, payload)
  },
  clearSwiftaidAuthorisation({ commit }: { commit: Commit }) {
    commit(CLEAR_SWIFTAID_AUTHORISATION)
  },
  setSwiftaidIsAuthorised({ commit }: { commit: Commit }, payload: any) {
    commit(SET_SWIFTAID_AUTHORISED, payload)
  },
  postAccountVerificationConfirmationCode(_: any, payload: any) {
    try {
      return api.postAccountVerificationConfirmationCode(payload)
    } catch (error) {
      sendExceptionToAnalytics(
        `/src/store/modules/donor.js#postAccountVerificationConfirmationCode: ${error}`
      )
    }
  },
}
