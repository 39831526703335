<script setup lang="ts">
import { computed } from 'vue'
import type { DonorInformation } from '@/types'

const props = defineProps<{
  userDetails: DonorInformation
}>()

const firstname = computed(() => props.userDetails.firstname)
const lastname = computed(() => props.userDetails.lastname)
const addressLine1 = computed(() => props.userDetails.addressLine1)
const addressLine2 = computed(() => props.userDetails.addressLine2)
const city = computed(() => props.userDetails.city)
const county = computed(() => props.userDetails.county)
const postcode = computed(() => props.userDetails.postcode)
const country = computed(() => props.userDetails.country)
</script>

<template>
  <div>
    <p class="m-0 lh-base" data-testid="personal-details">
      <strong class="fw-bold d-inline-block mb-2">
        {{ firstname }} {{ lastname }}
      </strong>
      <br />
      {{ addressLine1 }}
      <br />
      {{ addressLine2 }}
      <br v-if="addressLine2" />
      {{ city }}
      <br v-if="city" />
      {{ county }}
      <br v-if="county" />
      <span class="text-uppercase">{{ postcode }}</span>
      <br />
      {{ country }}
    </p>
  </div>
</template>
