<script setup lang="ts">
import { ref, computed, onBeforeMount, onMounted } from 'vue'
import { useStore } from 'vuex'
import TermsModal from '@/components/views/login-registration/modals/TermsModal.vue'
import PrivacyModal from '@/components/views/login-registration/modals/PrivacyModal.vue'
// @ts-ignore
import Navbar from '@/components/generic/Navbar.vue'
// @ts-ignore
import Mobile from '@/components/generic/menu/Mobile.vue'
// @ts-ignore
import Aside from '@/components/generic/menu/Aside.vue'
// @ts-ignore
import NoLinkedAccountsNotification from '@/components/views/dashboard/generic/NoLinkedAccountsNotification.vue'
// @ts-ignore
import NoSwiftaidAuthorisationNotification from '@/components/views/dashboard/generic/NoSwiftaidAuthorisationNotification.vue'

const store = useStore()

const dashboard = ref(true)

const isLoading = computed(() => store.state.isLoading)
const termsModalIsVisible = computed(() => store.state.termsModalIsVisible)
const privacyModalIsVisible = computed(() => store.state.privacyModalIsVisible)

onBeforeMount(() => {
  document.body.className = 'dashboard-layout'
})

onMounted(() => {
  dashboard.value = true
  store.dispatch('setLoading', true)
})
</script>

<template>
  <div>
    <Navbar :dashboard="dashboard" />
    <Mobile class="d-block d-lg-none" />
    <div class="d-flex">
      <Aside class="d-none d-lg-block" />
      <div id="content-wrap" class="content-wrap">
        <main id="content" class="content">
          <NoLinkedAccountsNotification />
          <NoSwiftaidAuthorisationNotification />
          <div v-if="isLoading" class="spinner vp-centered"></div>
          <router-view v-show="!isLoading" role="main"></router-view>
        </main>
      </div>
    </div>
    <TermsModal v-if="termsModalIsVisible" />
    <PrivacyModal v-if="privacyModalIsVisible" />
  </div>
</template>
