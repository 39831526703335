<script setup lang="ts">
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { IS_SETTINGS_PAGE } from '@/store/mutation-types'
import AuthorisationSection from '@/components/views/dashboard/settings/AuthorisationSection.vue'
import EmailSection from '@/components/views/dashboard/settings/EmailSection.vue'
import PersonalDetailsSection from '@/components/views/dashboard/settings/PersonalDetailsSection.vue'
import CancellationSection from '@/components/views/dashboard/settings/CancellationSection.vue'

const store = useStore()

onMounted(async () => {
  await store.dispatch('fetchAuth')
  store.commit(IS_SETTINGS_PAGE, true)
  setTimeout(() => {
    store.commit('SET_LOADING', false)
  }, 500)
})
</script>

<template>
  <div role="main">
    <transition name="fade" mode="out-in" appear>
      <div id="settingsWrapper">
        <AuthorisationSection />
        <EmailSection />
        <PersonalDetailsSection />
        <CancellationSection />
      </div>
    </transition>
  </div>
</template>
