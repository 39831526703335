import {
  SET_USER_STATEMENTS,
  SET_DONOR_DONATIONS,
  SET_AUTHORISATION,
  CLEAR_SWIFTAID_AUTHORISATION,
  SET_SWIFTAID_AUTHORISED,
  SET_USER,
  SET_POSTED_USER,
  SET_REFERRAL_LINK,
  SET_LANDING_PAGE,
  SET_USER_EMAIL,
} from '@/store/mutation-types'

export default {
  [SET_USER_STATEMENTS](state: any, payload: any) {
    state.statements = payload
  },
  [SET_DONOR_DONATIONS](state: any, payload: any) {
    state.financialYears = payload.financialYears
  },
  [SET_AUTHORISATION](state: any, payload: any) {
    state.authorisation = payload
  },
  [CLEAR_SWIFTAID_AUTHORISATION](state: any) {
    state.authorisation = {}
  },
  [SET_SWIFTAID_AUTHORISED](state: any, payload: any) {
    state.swiftaidAuthorised = payload
  },
  [SET_USER](state: any, payload: any) {
    state.userDetails = payload
  },
  [SET_POSTED_USER](state: any, payload: any) {
    state.userDetails.details = payload
  },
  [SET_REFERRAL_LINK](state: any, payload: any) {
    state.referralLink = payload
  },
  [SET_LANDING_PAGE](state: any, payload: any) {
    state.userDetails.landingPage = payload
  },
  [SET_USER_EMAIL](state: any, payload: any) {
    state.userDetails.emailAddress = payload
  },
}
