import mutations from './mutations'
import actions from './actions'
import {
  ACCOUNT_TYPE_CARD,
  ACCOUNT_TYPE_PAR,
  ACCOUNT_TYPE_EMAIL,
  ACCOUNT_TYPE_PHONE,
} from './account-types'

export default {
  namespaced: true,
  state: {
    accountTypeConstant: {
      card: ACCOUNT_TYPE_CARD,
      par: ACCOUNT_TYPE_PAR,
      email: ACCOUNT_TYPE_EMAIL,
      phone: ACCOUNT_TYPE_PHONE,
    },
    accounts: [],
    fidelAccounts: [],
    cardAccounts: [],
    parAccounts: [],
    emailAccounts: [],
    phoneAccounts: [],
    accountToBeDeleted: '',
    accountToBeDeletedMeta: {},
    currentAccountId: '',
    accountsBusy: false,
    hasAccounts: false,
    placeholderAccount: false,
  },
  mutations,
  actions,
}
