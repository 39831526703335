<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const props = defineProps<{
  accountId: string
}>()

const isOpen = computed(() => {
  return store.state.accounts.currentAccountId === props.accountId
})

const openMenu = () => {
  store.dispatch('accounts/setCurrentAccountId', props.accountId)
}
</script>

<template>
  <button class="dash-account__menu-button text-link" @click="openMenu">
    <i class="dash-account__menu-button-icon fas fa-ellipsis-h"></i>
    <transition name="pop-out-menu" mode="out-in" appear>
      <div v-if="isOpen" class="dash-account__menu">
        <div class="dash-account__open-menu-items">
          <slot />
        </div>
      </div>
    </transition>
  </button>
</template>
