<script setup lang="ts">
import { onMounted, ref, inject } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const bootstrap: any = inject('bootstrap')

const link = ref(import.meta.env.VITE_LEGAL_PRIVACY_LINK)
const backupLink = ref(import.meta.env.VITE_LEGAL_PRIVACY_LINK_BACKUP)
const loadingMsg = ref('Loading...')
const loadingError = ref(false)

onMounted(() => {
  const loadingTimeout = setTimeout(() => {
    loadingError.value = true
    loadingMsg.value = 'Loading the document is taking longer than expected.'
  }, 4000)

  fetch(link.value)
    .then((response) => response.text())
    .then((htmlResponse) => {
      clearTimeout(loadingTimeout)
      const incomingHtml = document.createElement('html')
      incomingHtml.innerHTML = htmlResponse
      const incomingContent = incomingHtml.querySelector(
        '#swiftaid-legal-doc-target-content'
      )
      const docElement = document.querySelector('.swiftaid-legal-doc')
      if (docElement && incomingContent) {
        docElement.innerHTML = incomingContent.innerHTML
      }
    })
    .catch((error) => {
      console.error('error', error)
      loadingError.value = true
      clearTimeout(loadingTimeout)
      loadingMsg.value = 'An error occurred.'
    })

  const privacyModal = new bootstrap.Modal('#privacyModal')
  privacyModal.show()

  document.addEventListener('hidden.bs.modal', () => {
    store.dispatch('setTermsModalVisibility', false)
  })
})
</script>

<template>
  <div
    id="privacyModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="privacyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="privacyModalLabel" class="modal-title">
            {{ $t('LoginRegister.Footer.PrivacyModal.Header') }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div
          id="legal-doc-modal-content"
          class="swiftaid-legal-doc modal-body text-left p-4"
        >
          {{ loadingMsg }}
          <span v-if="loadingError" class="msg">
            <a :href="backupLink" target="_blank">View in a new window</a>
          </span>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="sa-btn sa-btn-primary-solid sa-btn-sm"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.msg {
  margin-top: 0.5rem;
  opacity: 0.75;
}
</style>
