<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const sidebarOpened = computed(() => store.state.sidebar.opened)
</script>

<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" href="/dashboard">
        <img
          src="@/assets/images/main/logo.svg"
          alt="An image of the Swiftaid logo"
        />
      </a>
      <span class="navbar-text d-lg-none">
        <div
          class="menu-wrapper"
          @click.prevent="store.commit('TOGGLE_SIDEBAR')"
        >
          <div class="hamburger-menu" :class="{ animate: sidebarOpened }"></div>
        </div>
      </span>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
$bar-width: 30px;
$bar-height: 4px;
$bar-spacing: 9px;

.menu-wrapper {
  margin: auto;
  width: $bar-width;
  height: $bar-height + $bar-spacing * 2;
  cursor: pointer;
}

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: $bar-width;
  height: $bar-height;
}

.hamburger-menu {
  position: relative;
  transform: translateY($bar-spacing);
  background: rgba(var(--black-rgb), 1);
  transition: all 0ms 300ms;

  &.animate {
    background: rgba(var(--black-rgb), 0);
  }
}

.hamburger-menu:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: $bar-spacing;
  background: rgba(var(--black-rgb), 1);
  transition:
    bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu:after {
  content: '';
  position: absolute;
  left: 0;
  top: $bar-spacing;
  background: rgba(var(--black-rgb), 1);
  transition:
    top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:after {
  top: 0;
  transform: rotate(45deg);
  transition:
    top 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.animate:before {
  bottom: 0;
  transform: rotate(-45deg);
  transition:
    bottom 300ms cubic-bezier(0.23, 1, 0.32, 1),
    transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
</style>
