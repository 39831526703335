export default {
  sidebarOpened: (state: any) => state.sidebar.opened,
  config: (state: any) => state.config,
  isLoading: (state: any) => state.isLoading,
  requestLoading: (state: any) => state.requestLoading,
  mode: (state: any) => state.mode,
  addAccountType: (state: any) => state.addAccountType,
  highlightDates: (state: any) => state.highlighted,
  disabledDates: (state: any) => state.disabledDates,
  globalError: (state: any) => state.globalError,
  isSettingsPage: (state: any) => state.isSettingsPage,
}
