<script setup lang="ts">
import { useStore } from 'vuex'

const store = useStore()

const { userDetails: user } = store.state.donor
</script>

<template>
  <div v-if="user.details">
    <h3 class="sub-text">{{ $t('Dashboard.Settings.Personal.FullName') }}</h3>
    <p class="text" data-testid="full-name">
      {{ user.details.firstname }} {{ user.details.lastname }}
    </p>
    <h3 class="sub-text">{{ $t('Dashboard.Settings.Personal.Address') }}</h3>
    <div class="ms-2"></div>
    <p class="text" data-testid="address">
      {{ user.details.addressLine1 }}
      <br />
      {{ user.details.addressLine2 }}
      <br />
      {{ user.details.city }}
      <br />
      {{ user.details.county }}
      <br />
      <span class="text-uppercase">
        {{ user.details.postcode }}
      </span>
      <br />
      {{ user.details.country }}
    </p>
  </div>
</template>
