<script setup lang="ts">
import { ref, computed, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { LoginMethod } from '@/types'

const store = useStore()

const TIMEOUT = 30000
let buttonTimer: any

const isSending = ref(false)
const progress = ref(0)
const timeout = ref(TIMEOUT)

const loginMethod = computed(() => store.state.auth.loginMethod)
const authEmail = computed(() => store.state.auth.authEmail)

const resendButtonText = computed(() => {
  return loginMethod.value === LoginMethod.CODE
    ? 'Buttons.ResendCode'
    : 'Buttons.ResendLink'
})

const progressBarStyle = computed(() => ({
  width: `${progress.value}%`,
  transition: `width ${timeout.value}ms linear`,
}))

const resendLink = async () => {
  isSending.value = true
  progress.value = 0
  timeout.value = TIMEOUT

  requestAnimationFrame(() => {
    progress.value = 100
  })

  await store.dispatch('sendLoginEmail', {
    email: authEmail.value,
    send: loginMethod.value,
  })

  // Set TIMEOUT to prevent spamming
  buttonTimer = setTimeout(() => {
    isSending.value = false
    progress.value = 0
    timeout.value = 0
  }, TIMEOUT)
}

onUnmounted(() => {
  clearTimeout(buttonTimer)
})
</script>

<template>
  <button
    class="sa-btn sa-btn-primary sa-btn-lg mx-auto"
    :class="{ active: isSending }"
    :disabled="isSending"
    data-testid="resend-link-button"
    @click="resendLink"
  >
    <span
      v-text="isSending ? $t('Buttons.Resending') : $t(resendButtonText)"
    ></span>
    <div class="progress-bar" :style="progressBarStyle"></div>
  </button>
</template>

<style scoped>
button {
  --progress-height: 1px;

  position: relative;
}

button.active {
  cursor: not-allowed;
}

button > .progress-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  height: var(--progress-height);
  background-color: var(--black);
  width: 0%;
}
</style>
