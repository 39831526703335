<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import unknownEmail from '@/assets/images/unknown-email.svg'
import Header from '@/components/views/manage-account/Header.vue'

const store = useStore()

const email = computed(() => store.state.auth.authEmail)
</script>

<template>
  <div class="container">
    <Header
      :img-src="unknownEmail"
      img-alt="Envelope with mystery letter inside"
      img-width="78px"
      img-margin-bottom="mb-4"
      :header="$t('ManageAccount.UserNotFound.Header')"
    >
      <template #custom>
        <p v-if="email">
          {{ $t('ManageAccount.UserNotFound.Paragraph') }}
          <span class="fw-bold d-block">
            {{ email }}
          </span>
        </p>
        <p v-else>{{ $t('ManageAccount.UserNotFound.ParagraphWithEmail') }}</p>
        <p>{{ $t('ManageAccount.UserNotFound.Instruction') }}</p>
      </template>
    </Header>

    <div class="row mb-4">
      <div class="col col mx-auto text-center">
        <p class="mb-4">
          <router-link
            :to="{ name: 'DONOR_FLOW_ManageAccountLogin' }"
            class="sa-btn sa-btn-secondary-solid sa-btn-sm mx-auto text-decoration-none d-inline-flex"
            v-text="$t('ManageAccount.UserNotFound.Button')"
          ></router-link>
        </p>
        <p>
          <a
            href="https://www.swiftaid.co.uk/donors/manage-account/something-else/"
            class="text-decoration-none"
          >
            {{ $t('ManageAccount.UserNotFound.HelpLink') }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>
