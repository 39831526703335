// App
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const SET_APP_LOADING = 'SET_APP_LOADING'
export const SET_LOADING = 'SET_LOADING'
export const SET_REQUEST_LOADING = 'SET_REQUEST_LOADING'
export const GLOBAL_ERROR = 'GLOBAL_ERROR'
export const IS_SETTINGS_PAGE = 'IS_SETTINGS_PAGE'
export const SET_PRINT_DATA = 'SET_PRINT_DATA'
export const SET_TERMS_MODAL_VISIBILITY = 'SET_TERMS_MODAL_VISIBILITY'
export const SET_PRIVACY_MODAL_VISIBILITY = 'SET_PRIVACY_MODAL_VISIBILITY'
export const SET_REFERRAL_EMAIL = 'SET_REFERRAL_EMAIL'
export const MAINTENANCE = 'MAINTENANCE'

// Accounts
export const CLEAR_ACCOUNTS = 'CLEAR_ACCOUNTS'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const SET_FIDEL_ACCOUNT = 'SET_FIDEL_ACCOUNT'
export const SET_CARD_ACCOUNT = 'SET_CARD_ACCOUNT'
export const SET_PAR_ACCOUNT = 'SET_PAR_ACCOUNT'
export const SET_EMAIL_ACCOUNT = 'SET_EMAIL_ACCOUNT'
export const SET_PHONE_ACCOUNT = 'SET_PHONE_ACCOUNT'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const SET_ACCOUNT_TO_DELETE = 'SET_ACCOUNT_TO_DELETE'
export const SET_ACCOUNT_TO_DELETE_META = 'SET_ACCOUNT_TO_DELETE_META'
export const SET_CURRENT_ACCOUNT_ID = 'SET_CURRENT_ACCOUNT_ID'
export const SET_ACCOUNTS_BUSY = 'SET_ACCOUNTS_BUSY'
export const UPDATE_PRIMARY_EMAIL_ACCOUNT = 'UPDATE_PRIMARY_EMAIL_ACCOUNT'

// Auth
export const SET_AUTH = 'SET_AUTH'
export const SET_TOKEN = 'SET_TOKEN'
export const TOGGLE_LOGIN_METHOD = 'TOGGLE_LOGIN_METHOD'
export const RESET_LOGIN_METHOD = 'RESET_LOGIN_METHOD'
export const SET_EMAIL_SENT_STATUS = 'SET_EMAIL_SENT_STATUS'
export const SET_FORM_SUBMITTED_STATUS = 'SET_FORM_SUBMITTED_STATUS'
export const SET_AUTH_EMAIL = 'SET_AUTH_EMAIL'
export const SET_BUTTON_LOADING_STATUS = 'SET_BUTTON_LOADING_STATUS'
export const SET_IS_VALID_EMAIL = 'SET_IS_VALID_EMAIL'
export const SET_FORM_CHANGED_STATUS = 'SET_FORM_CHANGED_STATUS'
export const RESET_REGISTRATION_STATE = 'RESET_REGISTRATION_STATE'
export const SET_CALLBACK_LANDING_PAGE = 'SET_CALLBACK_LANDING_PAGE'
export const RESET_CALLBACK_LANDING_PAGE = 'RESET_CALLBACK_LANDING_PAGE'

// Donor
export const SET_USER_STATEMENTS = 'SET_USER_STATEMENTS'
export const SET_DONOR_DONATIONS = 'SET_DONOR_DONATIONS'
export const SET_AUTHORISATION = 'SET_AUTHORISATION'
export const CLEAR_SWIFTAID_AUTHORISATION = 'CLEAR_SWIFTAID_AUTHORISATION'
export const SET_SWIFTAID_AUTHORISED = 'SET_SWIFTAID_AUTHORISED'
export const SET_USER = 'SET_USER'
export const SET_POSTED_USER = 'SET_POSTED_USER'
export const SET_REFERRAL_LINK = 'SET_REFERRAL_LINK'
export const DO_NOT_AUTO_ENROL = 'DO_NOT_AUTO_ENROL'
export const AUTO_ENROL = 'AUTO_ENROL'
export const SET_LANDING_PAGE = 'SET_LANDING_PAGE'
export const SET_USER_EMAIL = 'SET_USER_EMAIL'
