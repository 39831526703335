<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import router from '@/router'
import Header from '@/components/views/manage-account/Header.vue'
import giftaiditLogo from '@/assets/images/giftaidit.svg'
import PersonalDetailsEditForm from '@/components/views/manage-account/PersonalDetailsEditForm.vue'
import { LANDING_PAGE } from '@/types'

const store = useStore()

const createDetailsPage = computed(() => {
  return (
    router.currentRoute.value.name === 'DONOR_FLOW_ManageAccountCreateDetails'
  )
})

const handleCancelEdit = () => {
  if (!createDetailsPage.value) return

  // We are on the onboarding flow
  // Update userDetails.landingPage because we don't fetch me again
  store.commit('SET_LANDING_PAGE', LANDING_PAGE.QUESTIONS)
  // move on to next screen: declarations
  router
    .push({ name: 'DONOR_FLOW_ManageAccountCreateDeclaration' })
    .catch(() => {})
}
</script>

<template>
  <div id="account" class="m-auto" data-testid="account-section">
    <Header
      v-if="createDetailsPage"
      :img-src="giftaiditLogo"
      :img-alt="$t('LoginRegister.Index.ImageAlt')"
      img-width="160px"
      :header="$t('LoginRegister.Details.Header')"
      :paragraph="$t('LoginRegister.Details.SubHeader')"
    />
    <div class="w-50 mx-auto">
      <PersonalDetailsEditForm
        @cancelEdit="handleCancelEdit"
        form-type="create"
      />
    </div>
  </div>
</template>
