<script setup lang="ts">
import { computed } from 'vue'
import Account from '@/components/views/dashboard/accounts/Account.vue'
import AccountMenu from '@/components/views/dashboard/accounts/AccountMenu.vue'
import { type Account as AccountType } from '@/types'

interface PropsInterface {
  accounts: Array<AccountType>
  accountType: string
  title: string
  lead: string
}

const props = defineProps<PropsInterface>()

const accountsCollection = computed(() => {
  return props.accounts.flat()
})
</script>

<template>
  <section v-if="accountsCollection.length">
    <h2 class="account-types__title">{{ title }}</h2>
    <p class="account-types__subheader">{{ lead }}</p>
    <Account
      v-for="account in accountsCollection"
      :key="account.id"
      :account="account"
      :account-type="accountType"
    >
      <AccountMenu
        v-if="accountType != 'emailAddress'"
        :account-id="account.id"
      >
        <slot name="menu-items" />
      </AccountMenu>
    </Account>
    <slot name="section-end" />
  </section>
</template>
