<template>
  <div id="placeHolderWrapper" class="d-flex justify-content-center my-5">
    <div class="d-flex flex-column col-sm-6 col-md-6 col-lg-5">
      <img
        id="heartOverHand"
        class="mb-4 ml-auto mr-auto"
        src="@/assets/images/main/donation.svg"
        :alt="$t('Dashboard.Donations.Image.HeartOverHand')"
      />
      <h1 class="mb-4 text-center">
        {{ $t('Dashboard.Donations.Not.Title') }}
      </h1>
      <p id="notSub" class="mb-4 text-center">
        {{ $t('Dashboard.Donations.Not.Sub') }}
      </p>
    </div>
  </div>
</template>
