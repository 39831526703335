<script setup lang="ts">
import { ref, computed, onMounted, inject } from 'vue'
import { useStore } from 'vuex'
import ApiErrorNotification from '@/components/generic/ApiErrorNotification.vue'
import AccountSection from '@/components/views/dashboard/accounts/AccountSection.vue'
import DeleteAccountModal from '@/components/views/dashboard/accounts/DeleteAccountModal.vue'

const store = useStore()
const bootstrap: any = inject('bootstrap')

const apiError = ref(false)

const fidelAccounts = computed(() => {
  return store.state.accounts.fidelAccounts
})

const cardAccounts = computed(() => {
  return store.state.accounts.cardAccounts
})

const parAccounts = computed(() => {
  return store.state.accounts.parAccounts
})

const emailAccounts = computed(() => {
  return store.state.accounts.emailAccounts
})

const phoneAccounts = computed(() => {
  return store.state.accounts.phoneAccounts
})

const accountToBeDeleted = computed(() => {
  return store.state.accounts.accountToBeDeleted
})

const accountTypeConstant = computed(() => {
  return store.state.accounts.accountTypeConstant
})

const placeholderAccount = computed(() => {
  return store.state.accounts.placeholderAccount
})

const noCardAccounts = computed(() => {
  try {
    return !(
      store.state.accounts.fidelAccounts.length ||
      store.state.accounts.cardAccounts.length ||
      store.state.accounts.parAccounts.length
    )
  } catch (e) {
    return false
  }
})

const noEmailAccounts = computed(() => {
  try {
    return !store.state.accounts.emailAccounts.length
  } catch (e) {
    return false
  }
})

const noPhoneAccounts = computed(() => {
  try {
    return !store.state.accounts.phoneAccounts.length
  } catch (e) {
    return false
  }
})

const hasAccountToDelete = computed(() => {
  return store.state.accounts.accountToBeDeleted !== ''
})

const cardAccount = computed(() => {
  return store.state.accounts.accountTypeConstant.card
})

const emailAccount = computed(() => {
  return store.state.accounts.accountTypeConstant.email
})

const phoneAccount = computed(() => {
  return store.state.accounts.accountTypeConstant.phone
})

const removeAccount = () => {
  setTimeout(() => {
    store.dispatch('accounts/setAccountToBeDeleted')
    const deleteAccountModal = new bootstrap.Modal('#deleteAccountModal')
    deleteAccountModal.show()
  }, 200)
}

onMounted(() => {
  store.commit('SET_LOADING', false)
})
</script>

<template>
  <div>
    <header class="mb-4">
      <h1>{{ $t('Dashboard.Accounts.ScreenTitle') }}</h1>
      <p>{{ $t('Dashboard.Accounts.ScreenSubTitle') }}</p>
      <p class="small">{{ $t('Dashboard.Accounts.ScreenChargeNote') }}</p>
    </header>

    <ApiErrorNotification
      v-if="apiError"
      link="Accounts"
      aria-label="Account API error"
    >
      An error has occurred while fetching your account, please
      <strong>try again</strong>
      .
    </ApiErrorNotification>

    <div v-else>
      <div v-if="noCardAccounts" class="prompt-card">
        <div class="prompt-card__info mb-0">
          <div class="prompt-card__image">
            <img
              src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='97' height='62' viewBox='0 0 97 62'%3E%3Cdefs%3E%3Cpath id='vqxpo0eeja' d='M0.187 0.168L96.566 0.168 96.566 61.349 0.187 61.349z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cmask id='2f4zxnbhhb' fill='%23fff'%3E%3Cuse xlink:href='%23vqxpo0eeja'/%3E%3C/mask%3E%3Cpath fill='%235A72FF' d='M93.164.168H3.59C1.71.168.187 1.69.187 3.568V57.95c0 1.877 1.523 3.399 3.402 3.399h89.575c1.879 0 3.402-1.522 3.402-3.4V3.568c0-1.877-1.523-3.399-3.402-3.399' mask='url(%232f4zxnbhhb)'/%3E%3Cpath stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.727' d='M37.602 22.023s2.887 4.038 0 8.798M41.659 20.148s4.118 5.76 0 12.548M45.716 18.273s5.348 7.481 0 16.298'/%3E%3Cpath fill='%23FFB0B9' fill-rule='nonzero' d='M25.771 18c-1.765 0-3.326.82-4.272 2.075C20.553 18.82 18.992 18 17.226 18 14.34 18 12 20.192 12 22.895 12 31.222 21.5 35 21.5 35S31 31.222 31 22.895C30.998 20.191 28.657 18 25.771 18z'/%3E%3C/g%3E%3C/svg%3E%0A"
              alt="Card icon"
            />
          </div>
          <div class="prompt-card__text">
            <h2 class="prompt-card__title">
              Here to add a card for contactless Gift Aid?
            </h2>
            <p class="prompt-card__para mb-0">
              We are sorry but due to a system change we can’t accept new cards
              cards at this time as we wait for our terminal provider partners
              to implement changes on their end.
              <br />
              <br />
              We will notify you as soon as this feature is back up and running,
              we are sorry for any inconvenience caused.
            </p>
          </div>
        </div>
      </div>

      <AccountSection
        :accounts="[emailAccounts]"
        class="account-types account-types--email"
        :title="$t('Dashboard.Accounts.EmailSection.Title')"
        :lead="$t('Dashboard.Accounts.EmailSection.SubTitle')"
        :account-type="emailAccount"
      >
        <template #menu-items>
          <a
            href="#"
            data-testid="remove-account"
            @click.stop.prevent="removeAccount"
          >
            REMOVE
          </a>
        </template>
      </AccountSection>

      <AccountSection
        :accounts="[fidelAccounts, cardAccounts, parAccounts]"
        class="account-types account-types--card"
        :title="$t('Dashboard.Accounts.CardSection.Title')"
        :lead="$t('Dashboard.Accounts.CardSection.SubTitle')"
        :account-type="cardAccount"
      >
        <template #menu-items>
          <a
            href="#"
            data-testid="remove-account"
            @click.stop.prevent="removeAccount"
          >
            REMOVE
          </a>
        </template>

        <template #section-end>
          <div v-if="placeholderAccount" class="account account--placeholder">
            <div class="account__icon">
              <div class="spinner btnSpinner"></div>
            </div>
            <div class="account__info fw-bold">
              Adding account...please wait
            </div>
          </div>
        </template>
      </AccountSection>

      <AccountSection
        :accounts="[phoneAccounts]"
        class="account-types account-types--phone mb-0"
        :title="$t('Dashboard.Accounts.PhoneSection.Title')"
        :lead="$t('Dashboard.Accounts.PhoneSection.SubTitle')"
        :account-type="phoneAccount"
      >
        <template #menu-items>
          <a
            href="#"
            data-testid="remove-account"
            @click.stop.prevent="removeAccount"
          >
            REMOVE
          </a>
        </template>
      </AccountSection>

      <DeleteAccountModal v-show="hasAccountToDelete" />
    </div>
  </div>
</template>

<style lang="scss">
header h1 {
  font-size: var(--font-size-xl);
}

.prompt-card {
  background-color: var(--white);
  padding: 0.75rem;
  margin-bottom: 1.95rem;

  @media (min-width: 768px) {
    padding: 1.25rem;
  }

  &__info {
    display: flex;
    margin-bottom: 0.7rem;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__image {
    margin-right: 1rem;
    margin-bottom: 1rem;

    img {
      width: 90px;
    }

    @media (min-width: 768px) {
      margin-bottom: 0;

      img {
        width: 105px;
      }
    }
  }

  &__title {
    font-size: var(--font-size-lg);
  }
}

.account-types {
  $account: '.account';

  margin-bottom: 2rem;

  #{$account}:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-size: var(--font-size-lg);
  }

  &__subheader {
    font-size: var(--font-size-md);
  }

  &--email {
    #{$account}__image {
      width: 40px;
    }

    #{$account}__icon {
      background-color: #d1d1d1;
    }
  }

  &--phone {
    #{$account}__image {
      width: 35px;
    }

    #{$account}__icon {
      background-color: #d1d1d1;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
