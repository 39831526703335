<script setup lang="ts">
import { ref, computed, inject, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import SwiftyCheck from '@/assets/images/swifty/swifty-check.svg'
import Header from '@/components/views/manage-account/Header.vue'
import PersonalDetails from '@/components/views/manage-account/PersonalDetails.vue'
import PersonalDetailsEditForm from '@/components/views/manage-account/PersonalDetailsEditForm.vue'
import Accounts from '@/components/views/manage-account/Accounts.vue'
import DeleteAccountModal from '@/components/views/dashboard/accounts/DeleteAccountModal.vue'
import InfoLinks from '@/components/views/manage-account/InfoLinks.vue'
import AddEmailAccount from '@/components/views/manage-account/AddEmailAccount.vue'

const store = useStore()

const bootstrap: any = inject('bootstrap')

const editDetails = ref(false)

const userDetails = computed(() => store.state.donor.userDetails)
const fidelAccounts = computed(() => store.state.accounts.fidelAccounts)
const cardAccounts = computed(() => store.state.accounts.cardAccounts)
const parAccounts = computed(() => store.state.accounts.parAccounts)
const emailAccounts = computed(() => store.state.accounts.emailAccounts)
const currentAccountId = computed(() => store.state.accounts.currentAccountId)
const hasAccountToDelete = computed(
  () => store.state.accounts.accountToBeDeleted !== ''
)

const toggleEditDetails = () => {
  editDetails.value = !editDetails.value
}

const handleCancelEdit = () => {
  editDetails.value = false
}

const removeAccount = async () => {
  store.dispatch('accounts/setAccountToBeDeleted', currentAccountId.value)

  await nextTick()

  const deleteAccountModal = new bootstrap.Modal('#deleteAccountModal')
  deleteAccountModal.show()
}

const setPrimaryAccount = async () => {
  // Store the current account ID on function call as currentAccountId.value may change
  const accountToPrimary = currentAccountId.value

  try {
    store.dispatch('postDonorInformation', {
      firstname: userDetails.value.details.firstname,
      lastname: userDetails.value.details.lastname,
      addressLine1: userDetails.value.details.addressLine1,
      addressLine2: userDetails.value.details.addressLine2,
      city: userDetails.value.details.city,
      county: userDetails.value.details.county,
      postcode: userDetails.value.details.postcode,
      country: userDetails.value.details.country,
      emailAddress: accountToPrimary,
    })

    // Update the UI
    setTimeout(() => {
      store.commit('SET_USER_EMAIL', accountToPrimary)
      store.commit('accounts/UPDATE_PRIMARY_EMAIL_ACCOUNT', accountToPrimary)
    }, 200)
  } catch (error) {
    console.error(error)
  }
}

onMounted(() => {
  store.dispatch('setLoading', false)
})
</script>

<template>
  <div class="container">
    <Header
      :img-src="SwiftyCheck"
      img-alt="Swifty reading"
      :header="$t('ManageAccount.UpdateAccount.Header')"
    />

    <section class="row mb-5" data-testid="personal-details-section">
      <div class="col rounded shadow p-1 p-sm-2 p-md-4">
        <div class="position-relative">
          <button
            v-if="!editDetails"
            class="position-absolute top-0 end-0 sa-btn sa-btn-sm sa-btn-primary"
            aria-label="Edit details"
            data-testid="edit-details-button"
            @click="toggleEditDetails"
          >
            <i class="fas fa-pencil-alt me-2"></i>
            {{ $t('ManageAccount.UpdateAccount.EditDetailsButton') }}
          </button>
          <div class="p-1 p-sm-2 p-md-3">
            <div v-if="!editDetails" class="lead m-0">
              <PersonalDetails
                v-if="userDetails.details && userDetails.details.firstname"
                :user-details="userDetails.details"
              />
              <div v-else>
                <p class="m-0 lh-base">
                  There are no user details,
                  <a href="#" @click.prevent="toggleEditDetails">
                    add some now
                  </a>
                </p>
              </div>
            </div>

            <div v-else class="col col-lg-8 mx-auto">
              <PersonalDetailsEditForm @cancelEdit="handleCancelEdit" />
            </div>
          </div>
        </div>
      </div>
    </section>

    <Accounts
      :accounts="[emailAccounts]"
      class="row mb-5"
      data-testid="email-accounts-section"
    >
      <template #header>
        <div class="col">
          <h2 class="fw-bold">Emails</h2>
          <p>
            Email addresses are used to log into Swiftaid and help us match Gift
            Aid to donations you make.
          </p>

          <p>
            Yearly Gift Aid statements will be sent to your primary email
            address. Please make sure your primary email is a personal one
            rather than a work one.
          </p>
        </div>
      </template>

      <template #menu-items>
        <button
          data-testid="make-primary"
          class="text-link"
          @click="setPrimaryAccount"
          v-text="$t('ManageAccount.UpdateAccount.AccountsMenu.MakePrimary')"
        ></button>
        <button
          data-testid="remove-account"
          class="text-link"
          @click="removeAccount"
          v-text="$t('ManageAccount.UpdateAccount.AccountsMenu.RemoveButton')"
        ></button>
      </template>

      <template #footer>
        <AddEmailAccount class="w-100" />
      </template>
    </Accounts>

    <Accounts
      :accounts="[fidelAccounts, cardAccounts, parAccounts]"
      class="row mb-5"
      data-testid="card-accounts-section"
    >
      <template #header>
        <div class="col">
          <h2 class="fw-bold">Cards</h2>
          <p>
            Payment cards linked to your Swiftaid account help us identify
            donations that qualify for Gift Aid. We don’t store payment card
            details and you will never be charged.
          </p>
        </div>
      </template>

      <template #menu-items>
        <button
          data-testid="remove-account"
          class="text-link"
          v-text="$t('ManageAccount.UpdateAccount.AccountsMenu.RemoveButton')"
          @click="removeAccount"
        ></button>
      </template>

      <template #footer>
        <p class="small">
          Looking to add another card? Cards are securely added when you make
          donations and add Gift Aid through JustGiving or other donation
          platforms that have integrated with Swiftaid.
        </p>
      </template>
    </Accounts>

    <DeleteAccountModal v-show="hasAccountToDelete" />

    <InfoLinks />
  </div>
</template>

<style scoped>
.container {
  max-width: 620px;
  margin: 0 auto;
}
</style>

<style lang="scss">
.accounts {
  $account: '.account';

  #{$account}:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-size: var(--font-size-lg);
  }

  &__subheader {
    font-size: var(--font-size-md);
  }

  &--email {
    #{$account}__image {
      width: 40px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
