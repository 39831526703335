<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import swiftaidLogo from '@/assets/images/main/logo.svg'
import { DateTime } from 'luxon'
import { moneyFormat } from '@/components/helpers/formats'

const store = useStore()

const data = computed(() => store.state.printData)
const totalDonated = computed(() => moneyFormat(data.value.totalDonated))
const totalGiftAid = computed(() => moneyFormat(data.value.totalGiftAid))
const taxYear = computed(() => data.value.period)

const formatDate = (date: string) => {
  try {
    return DateTime.fromISO(date).toFormat('yyyy-MM-dd')
  } catch (error) {
    return 'N/A'
  }
}
</script>

<template>
  <div
    v-if="data"
    id="print-wrapper"
    class="d-none d-print-block print-wrapper container"
  >
    <div class="row mb-5">
      <div class="col-12">
        <img
          id="printLogo"
          :src="swiftaidLogo"
          alt="swiftaid logo and swiftaid text"
          class="statement-logo-print"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="m-b-40">
          <p class="statement-text-date">{{ data.period }}</p>
          <p class="statement-text-sub">
            {{ $t('Dashboard.Statements.Print.Statement') }}
          </p>
        </div>
        <div class="m-b-40">
          <p class="statement-text-money">
            £{{ moneyFormat(data.totalDonated) }}
          </p>
          <p class="statement-text-title">
            {{ $t('Dashboard.Statements.Print.Donated') }}
          </p>
        </div>
        <div class="m-b-40">
          <p class="statement-text-money">
            £{{ moneyFormat(data.totalGiftAid) }}
          </p>
          <p class="statement-text-title">
            {{ $t('Dashboard.Statements.Print.GiftAid') }}
          </p>
        </div>
      </div>
      <div class="col-6">
        <div class="m-b-20">
          <p class="statement-bold-title">
            {{ $t('Dashboard.Statements.Print.Donor') }}
          </p>
          <p class="statement-text-title">
            {{ data.donor.title }} {{ data.donor.firstname }}
            {{ data.donor.lastname }}
          </p>
          <p class="statement-text-title">{{ data.donor.addressLine1 }}</p>
          <p class="statement-text-title">{{ data.donor.postcode }}</p>
        </div>
        <div class="m-b-20">
          <p class="statement-bold-title">
            {{ $t('Dashboard.Statements.Print.DonorIntermediary') }}
          </p>
          <p class="statement-text-title">
            {{ $t('Dashboard.Statements.Print.Streeva') }}
          </p>
        </div>
        <div class="m-b-20">
          <p class="statement-bold-title">
            {{ $t('Dashboard.Statements.Print.Issued') }}
          </p>
          <p class="statement-text-title">{{ formatDate(data.issued) }}</p>
        </div>
        <div class="m-b-20">
          <p class="statement-bold-title">
            {{ $t('Dashboard.Statements.Print.Ref') }}
          </p>
          <p class="statement-text-title">{{ data.id }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="statement-text-sub mt-5">
          {{
            $t('Dashboard.Statements.Print.Summary', {
              totalDonated,
              taxYear,
              totalGiftAid,
            })
          }}
        </p>
        <div
          class="statement-bottom-box d-flex mt-5 p-3 justify-content-center align-items-center"
        >
          <i class="fas fa-exclamation-triangle fa-3x me-3 text-black-50"></i>
          <p class="statement-text-title warning mt-0">
            {{ $t('Dashboard.Statements.Print.Warning') }}
          </p>
        </div>
        <div
          class="statement-bottom-box d-flex mt-5 p-3 justify-content-center align-items-center"
        >
          <i class="fas fa-exclamation-triangle fa-3x me-3 text-black-50"></i>
          <p class="statement-text-title warning mt-0">
            {{ $t('Dashboard.Statements.Print.Warning2') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
