import find from 'lodash/find'
import api from '@/store/api/donor'
import { sendExceptionToAnalytics } from '@/services/googleAnalytics/errorHandling'
import { GLOBAL_ERROR } from '@/store/mutation-types'
import {
  CardAccount,
  FidelAccount,
  ParAccount,
  PhoneAccount,
  EmailAccount,
} from './account-types'
import {
  CLEAR_ACCOUNTS,
  SET_ACCOUNT,
  SET_FIDEL_ACCOUNT,
  SET_CARD_ACCOUNT,
  SET_PAR_ACCOUNT,
  SET_EMAIL_ACCOUNT,
  SET_PHONE_ACCOUNT,
  DELETE_ACCOUNT,
  SET_ACCOUNT_TO_DELETE,
  SET_ACCOUNT_TO_DELETE_META,
  SET_CURRENT_ACCOUNT_ID,
  SET_ACCOUNTS_BUSY,
} from '@/store/mutation-types'
import type { Commit, Dispatch } from 'vuex'

export default {
  addAccounts(
    {
      state,
      commit,
      rootState,
    }: { state: any; commit: Commit; rootState: any },
    accountsArray: any[]
  ) {
    commit(CLEAR_ACCOUNTS)

    for (const account of accountsArray) {
      commit(SET_ACCOUNT, account)

      if (account.type === state.accountTypeConstant.card) {
        if (account.id.includes('fidel.')) {
          const fidelAccount = new FidelAccount(account)
          commit(SET_FIDEL_ACCOUNT, fidelAccount.output())
        } else {
          const cardAccount = new CardAccount(account)
          commit(SET_CARD_ACCOUNT, cardAccount.output())
        }
      }

      if (account.type === state.accountTypeConstant.par) {
        const parAccount = new ParAccount(account)
        commit(SET_PAR_ACCOUNT, parAccount.output())
      }

      if (account.type === state.accountTypeConstant.email) {
        const emailAccount = new EmailAccount(account)
        emailAccount.tagAsPrimary(rootState.donor.userDetails.emailAddress)
        commit(SET_EMAIL_ACCOUNT, emailAccount.output())
      }

      if (account.type === state.accountTypeConstant.phone) {
        const phoneAccount = new PhoneAccount(account)
        commit(SET_PHONE_ACCOUNT, phoneAccount.output())
      }
    }
  },
  setAccountToBeDeleted(
    { commit, state }: { commit: Commit; state: any },
    id: string
  ) {
    commit(SET_ACCOUNT_TO_DELETE, id)

    const accountObjToBeDeleted = find(state.accounts, { id })
    let type, ref
    switch (accountObjToBeDeleted.type) {
      case state.accountTypeConstant.card:
        type = 'card'
        ref = `ending ${accountObjToBeDeleted.reference}`
        break
      case state.accountTypeConstant.par:
        type = 'card'
        ref = `ending ${accountObjToBeDeleted.last4}`
        break
      case state.accountTypeConstant.email:
        type = 'email address'
        ref = accountObjToBeDeleted.id
        break
      case state.accountTypeConstant.phone:
        type = 'phone number'
        ref = accountObjToBeDeleted.id
        break
    }

    commit(SET_ACCOUNT_TO_DELETE_META, { type, ref })
  },
  clearAccountToBeDeleted({ commit }: { commit: Commit }) {
    commit(SET_ACCOUNT_TO_DELETE, '')
    commit(SET_ACCOUNT_TO_DELETE_META, {})
  },
  setCurrentAccountId(
    { commit, state }: { commit: Commit; state: any },
    accountId: string
  ) {
    if (accountId === state.currentAccountId) {
      commit(SET_CURRENT_ACCOUNT_ID, '')
    } else {
      commit(SET_CURRENT_ACCOUNT_ID, accountId)
    }
  },
  async deleteAccount(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: any
  ) {
    try {
      const response = await api.deleteAccount(payload)
      dispatch('removeAccountFromUI')
      return response
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        commit(GLOBAL_ERROR, 'Dashboard.Accounts.AccountNotFoundError', {
          root: true,
        })
      }
      sendExceptionToAnalytics(
        `/src/store/modules/accounts#deleteAccount: ${error}`
      )
    }
  },
  removeAccountFromUI({ commit }: { commit: Commit }) {
    commit(DELETE_ACCOUNT)
    commit(SET_ACCOUNT_TO_DELETE, '')
  },
  setAccountsBusy({ commit }: { commit: Commit }) {
    commit(SET_ACCOUNTS_BUSY, true)
  },
  setAccountsNotBusy({ commit }: { commit: Commit }) {
    commit(SET_ACCOUNTS_BUSY, false)
  },
  postAccount(_: any, payload: any) {
    try {
      return api.postAccount(payload)
    } catch (error) {
      sendExceptionToAnalytics(
        `/src/store/modules/accounts#postAccount: ${error}`
      )
    }
  },
}
