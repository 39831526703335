<script setup lang="ts">
import { computed } from 'vue'

const RECEIVED = 'received'
const SETTLED = 'settled'
const DECLARED = 'declared'
const CANCELLED = 'cancelled'
const DECLARED_CANCELLED = 'declaredCancelled'
const DECLARED_CANCELLING = 'declaredCancelling'
const PENDING = 'pending'

const props = defineProps({
  rowData: {
    type: Object,
    required: true,
  },
})

const stateObject = computed(() => {
  switch (props.rowData.state) {
    case RECEIVED:
    case SETTLED:
      return {
        text: 'Dashboard.Donations.DonationStatus.Processing',
        cssClass: 'text-warning',
        ariaText: 'donation processing',
      }
    case DECLARED:
      return {
        text: 'Dashboard.Donations.DonationStatus.Added',
        cssClass: 'text-primary',
        ariaText: 'donation added',
      }
    case CANCELLED:
    case DECLARED_CANCELLED:
      return {
        text: 'Dashboard.Donations.DonationStatus.Cancelled',
        cssClass: 'text-danger',
        ariaText: 'donation cancelled',
      }
    case DECLARED_CANCELLING:
      return {
        text: 'Dashboard.Donations.DonationStatus.PendingCancellation',
        cssClass: 'text-danger',
        ariaText: 'donation pending cancellation',
      }
    case PENDING:
      return {
        text: 'Dashboard.Donations.DonationStatus.OnHold',
        cssClass: 'text-info',
        ariaText: 'donation on hold',
      }
    default:
      return {
        text: 'Dashboard.Donations.DonationStatus.OnHold',
        cssClass: 'text-info',
        ariaText: 'donation on hold',
      }
  }
})
</script>

<template>
  <div class="d-flex flex-column align-items-end" aria-label="donation state">
    <p
      class="mb-2"
      :class="stateObject.cssClass"
      :aria-label="stateObject.ariaText"
    >
      {{ $t(stateObject.text) }}
    </p>
  </div>
</template>
