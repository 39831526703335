<template>
  <div class="container mb-4">
    <div class="side text-center"><slot name="side"></slot></div>
    <div class="main text-left">
      <p v-if="$slots.title" class="fw-bold mb-2">
        <slot name="title"></slot>
      </p>
      <slot name="text"></slot>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 0.2fr 1.8fr;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'side main';
}

.side {
  grid-area: side;
}

.main {
  grid-area: main;
}
</style>
