<script setup lang="ts">
import { ref, computed } from 'vue'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import { moneyFormat, dateFormat } from '@/components/helpers/formats'
import DonationStatus from '@/components/views/dashboard/donations/DonationStatus.vue'

const props = defineProps({
  donorDonations: {
    type: Object,
    required: true,
  },
})

const sortByDateDesc = ref(true)

const sortedDonations = computed(() => {
  const sortedDonations = sortBy(props.donorDonations.donations, ['time'])
  if (sortByDateDesc.value) {
    return reverse(sortedDonations)
  } else {
    return sortedDonations
  }
})

const sortToggle = () => {
  sortByDateDesc.value = !sortByDateDesc.value
}
</script>

<template>
  <div class="bg-white donations-list p-4">
    <div
      class="align-content-center d-flex justify-content-between donations-list__header"
    >
      <h4 class="mb-0">{{ $t('Dashboard.Donations.Table.Headings.Donor') }}</h4>
      <div
        v-if="sortedDonations.length"
        class="small align-items-center d-flex justify-content-center list-inline mb-0"
      >
        <span class="list-inline-item fw-bold">
          {{ $t('Dashboard.Donations.OrderBy') }}
        </span>
        <span class="align-items-center d-flex">
          <span v-if="sortByDateDesc">
            {{ $t('Dashboard.Donations.MostRecent') }}
          </span>
          <span v-else>{{ $t('Dashboard.Donations.LeastRecent') }}</span>
          <a
            href="#"
            class="sort-icon__container flex-shrink-0"
            @click.prevent="sortToggle"
          >
            <i
              class="sort-icon__icon sort-icon__down fas fa-sort-down"
              :class="sortByDateDesc ? 'active' : ''"
            ></i>
            <i
              class="sort-icon__icon sort-icon__up fas fa-sort-up"
              :class="sortByDateDesc ? '' : 'active'"
            ></i>
            <span class="sr-only">
              {{ sortByDateDesc ? 'Up' : 'Down' }}
            </span>
          </a>
        </span>
      </div>
    </div>
    <div v-if="!sortedDonations.length" class="pt-3">
      {{ $t('Dashboard.Donations.Table.EmptyMessage') }}
    </div>
    <div
      v-for="donation in sortedDonations"
      :key="donation.id"
      class="bg-white row border-top pt-3 mt-5 donations-list__item"
    >
      <div class="col">
        <h5>{{ donation.charity }}</h5>
        <p class="mb-0 text-muted small-line-height">
          <strong>£{{ moneyFormat(donation.amount) }}</strong>
          <span class="ms-1 small">
            {{ $t('Dashboard.Donations.Donated') }}
            {{ dateFormat(donation.time) }}
          </span>
        </p>
      </div>
      <div class="col">
        <p class="text-right mb-2 small-line-height">
          <strong>£{{ moneyFormat(donation.giftaid) }}</strong>
          <span class="small text-muted ms-1">
            {{ $t('Dashboard.Donations.GiftAid') }}
          </span>
        </p>
        <div class="d-flex justify-content-end text-right">
          <DonationStatus :row-data="donation" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sort-icon {
  &__container {
    background: var(--light-grey);
    border-radius: 3px;
    color: darken(#f8f8f8, 10%);
    position: relative;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-left: 5px;

    &:hover {
      background: darken(#f8f8f8, 2%);
    }
  }

  &__icon {
    position: absolute;
    transition: color 0.2s ease-in-out;
    width: 10px;
    height: 10px;
    left: 11px;

    &.active {
      color: var(--brand-primary);
    }
  }

  &__up {
    top: 7px;
  }

  &__down {
    bottom: 8px;
  }
}
</style>
