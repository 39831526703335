import { nextTick } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'
import store from '@/store'
import { SET_REFERRAL_EMAIL } from '@/store/mutation-types'
import debug from '@/helpers/debug'
import {
  requiresUserToBeUnauthenticated,
  requiresUserToBeAuthenticated,
  handleSignUpState,
  setRequestLoadingFalse,
  redirectOnAuthCallbackError,
  storeRequestedPage,
  catchAuth0CallbackQueryParam,
  checkLoginMethod,
  debugRouterGuard,
} from '@/router/guards'

import AccountVerify from '@/views/account-verify.vue'

// ManageAccount
import ManageAccount from '@/views/manage-account/index.vue'
import ManageAccountLogin from '@/views/manage-account/login.vue'
import ManageAccountUpdate from '@/views/manage-account/update.vue'
import ManageAccountSuspendRouterView from '@/views/manage-account/suspend/router-view.vue'
import ManageAccountSuspend from '@/views/manage-account/suspend/index.vue'
import ManageAccountSuspendConfirm from '@/views/manage-account/suspend/confirm.vue'
import ManageAccountCloseRouterView from '@/views/manage-account/close/router-view.vue'
import ManageAccountClose from '@/views/manage-account/close/index.vue'
import ManageAccountCloseConfirm from '@/views/manage-account/close/confirm.vue'
import ManageAccountUserNotFound from '@/views/manage-account/user-not-found.vue'
import ManageAccountCreateRouterView from '@/views/manage-account/create/router-view.vue'
import ManageAccountCreateDetails from '@/views/manage-account/create/details.vue'
import ManageAccountCreateDeclaration from '@/views/manage-account/create/declaration.vue'

// Dashboard
import DashboardRouterView from '@/views/dashboard/router-view.vue'
import DashboardDonations from '@/views/dashboard/donations.vue'
import DashboardAccounts from '@/views/dashboard/accounts.vue'
import DashboardStatements from '@/views/dashboard/statements.vue'
import DashboardSettings from '@/views/dashboard/settings/index.vue'
import DashboardSettingsRenewAuthorisation from '@/views/dashboard/settings/renew-authorisation.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: 'Root',
      path: '/',
      redirect: { name: 'DONOR_FLOW_ManageAccountLogin' },
    },
    {
      name: 'Maintenance',
      path: '/maintenance',
      component: () => import('@/layouts/MaintenanceLayout.vue'),
      meta: {
        title: 'Maintenance',
      },
    },
    {
      name: 'LegacyLogin',
      path: '/login',
      redirect: { name: 'DONOR_FLOW_ManageAccountLogin' },
    },
    {
      path: '/manage-account',
      component: ManageAccount,
      meta: {
        layout: 'BlankLayout',
        title: 'Manage Account',
      },
      children: [
        {
          name: 'DONOR_FLOW_ManageAccountLogin',
          path: '',
          component: ManageAccountLogin,
          meta: {
            layout: 'BlankLayout',
            manageAccount: true,
            title: 'Login | Manage account',
          },
          beforeEnter: [
            checkLoginMethod,
            requiresUserToBeUnauthenticated,
            setRequestLoadingFalse,
          ],
        },
        {
          path: '/manage-account/create',
          component: ManageAccountCreateRouterView,
          meta: {
            layout: 'BlankLayout',
            manageAccount: true,
          },
          children: [
            {
              name: 'DONOR_FLOW_ManageAccountCreateDetails',
              path: '/manage-account/create/details',
              component: ManageAccountCreateDetails,
              meta: {
                manageAccount: true,
                title: 'Registration details',
                layout: 'BlankLayout',
              },
              beforeEnter: [requiresUserToBeAuthenticated, handleSignUpState],
            },
            {
              name: 'DONOR_FLOW_ManageAccountCreateDeclaration',
              path: '/manage-account/create/declaration',
              component: ManageAccountCreateDeclaration,
              meta: {
                manageAccount: true,
                title: 'Registration declaration',
                layout: 'BlankLayout',
              },
              beforeEnter: [requiresUserToBeAuthenticated, handleSignUpState],
            },
          ],
        },
        {
          name: 'DONOR_FLOW_ManageAccountUpdate',
          path: '/manage-account/update',
          component: ManageAccountUpdate,
          meta: {
            layout: 'BlankLayout',
            manageAccount: true,
            title: 'Update | Manage account',
          },
          beforeEnter: [
            storeRequestedPage,
            requiresUserToBeAuthenticated,
            handleSignUpState,
          ],
        },
        {
          path: '/manage-account/suspend',
          component: ManageAccountSuspendRouterView,
          meta: {
            layout: 'BlankLayout',
            manageAccount: true,
          },
          beforeEnter: [
            storeRequestedPage,
            requiresUserToBeAuthenticated,
            handleSignUpState,
          ],
          children: [
            {
              name: 'DONOR_FLOW_ManageAccountSuspend',
              path: '',
              component: ManageAccountSuspend,
              meta: {
                layout: 'BlankLayout',
                manageAccount: true,
                title: 'Suspend | Manage account',
              },
              beforeEnter: [requiresUserToBeAuthenticated, handleSignUpState],
            },
            {
              name: 'DONOR_FLOW_ManageAccountSuspendConfirm',
              path: '/manage-account/suspend/confirm',
              component: ManageAccountSuspendConfirm,
              meta: {
                layout: 'BlankLayout',
                manageAccount: true,
                title: 'Confirm suspend | Manage account',
              },
              beforeEnter: [requiresUserToBeAuthenticated, handleSignUpState],
            },
          ],
        },
        {
          path: '/manage-account/close',
          component: ManageAccountCloseRouterView,
          meta: {
            layout: 'BlankLayout',
            manageAccount: true,
          },
          beforeEnter: [storeRequestedPage, requiresUserToBeAuthenticated],
          children: [
            {
              name: 'DONOR_FLOW_ManageAccountClose',
              path: '',
              component: ManageAccountClose,
              meta: {
                layout: 'BlankLayout',
                manageAccount: true,
                title: 'Close | Manage account',
              },
              beforeEnter: [requiresUserToBeAuthenticated],
            },
            {
              name: 'DONOR_FLOW_ManageAccountCloseConfirm',
              path: '/manage-account/close/confirm',
              component: ManageAccountCloseConfirm,
              meta: {
                layout: 'BlankLayout',
                manageAccount: true,
                title: 'Confirm close | Manage account',
              },
              beforeEnter: [requiresUserToBeAuthenticated],
            },
          ],
        },
        {
          name: 'DONOR_FLOW_ManageAccountUserNotFound',
          path: '/manage-account/user-not-found',
          component: ManageAccountUserNotFound,
          meta: {
            layout: 'BlankLayout',
            manageAccount: true,
            title: 'User not found | Manage account',
          },
          beforeEnter: [setRequestLoadingFalse],
        },
      ],
    },
    {
      path: '/dashboard',
      redirect: { name: 'Dashboard' },
      component: DashboardRouterView,
      meta: {
        default: true,
        iconClass: 'fas fa-fw fa-hand-holding-heart',
        title: 'Dashboard',
        layout: 'DashboardLayout',
      },
      beforeEnter: [requiresUserToBeAuthenticated, handleSignUpState],
      children: [
        {
          name: 'Dashboard',
          path: '/dashboard/donations',
          component: DashboardDonations,
          meta: {
            title: 'Donations | Dashboard',
            layout: 'DashboardLayout',
          },
          beforeEnter: [requiresUserToBeAuthenticated, handleSignUpState],
        },
        {
          name: 'Accounts',
          path: '/dashboard/accounts',
          component: DashboardAccounts,
          meta: {
            title: 'Accounts | Dashboard',
            layout: 'DashboardLayout',
          },
          beforeEnter: [requiresUserToBeAuthenticated, handleSignUpState],
        },
        {
          name: 'Statements',
          path: '/dashboard/statements',
          component: DashboardStatements,
          meta: {
            title: 'Statements | Dashboard',
            layout: 'DashboardLayout',
          },
          beforeEnter: [requiresUserToBeAuthenticated, handleSignUpState],
        },
        {
          name: 'Settings',
          path: '/dashboard/settings',
          component: DashboardSettings,
          meta: {
            title: 'Settings | Dashboard',
            layout: 'DashboardLayout',
          },
          beforeEnter: [requiresUserToBeAuthenticated, handleSignUpState],
        },
        {
          name: 'Renew',
          path: '/dashboard/settings/renew-authorisation',
          component: DashboardSettingsRenewAuthorisation,
          meta: {
            title: 'Renew authorisation | Dashboard',
            layout: 'DashboardLayout',
          },
          beforeEnter: [requiresUserToBeAuthenticated, handleSignUpState],
        },
      ],
    },
    {
      name: 'AccountVerify',
      path: '/account-verify',
      component: AccountVerify,
      meta: {
        title: 'Account verification',
        layout: 'BlankLayout',
      },
      beforeEnter: [setRequestLoadingFalse],
    },
    {
      name: 'Callback',
      path: '/callback',
      component: () => import('@/layouts/BlankLayout.vue'),
      meta: {
        title: 'Signing in',
      },
      beforeEnter: [
        debugRouterGuard,
        catchAuth0CallbackQueryParam,
        requiresUserToBeUnauthenticated,
        redirectOnAuthCallbackError,
        handleSignUpState,
      ],
    },
  ],
})

router.beforeEach(async (to, from) => {
  debug(
    'Soft navigated to',
    `${to.fullPath} called: ${String(to.name)} on ${Date.now()}`
  )

  // Send user to maintenance page if maintenance mode is on
  if (import.meta.env.VITE_MAINTENANCE || store.state.maintenance) {
    if (to.name !== 'Maintenance') {
      return { name: 'Maintenance' }
    }

    return true
  }

  // Store referrer email query param on any path (...co.uk?email=test@example.com).
  try {
    const { email } = to.query || from.query
    if (email) {
      store.commit(SET_REFERRAL_EMAIL, email)
    }
  } catch (error) {
    debug(error)
  }

  // Dispatch necessary actions and bypass rest of guard
  // if using mock API/testing.
  if (import.meta.env.MODE === 'mockapi') {
    store.dispatch('storeMockToken')
    await store.dispatch('fetchUser')
    return true
  }

  return true
})

const DEFAULT_TITLE = 'Swiftaid for donors'
router.afterEach((to) => {
  nextTick(() => {
    let title = DEFAULT_TITLE

    if (to.meta.title) {
      title = `${to.meta.title} | ${DEFAULT_TITLE}`
    }

    document.title = title
    document
      .querySelector('meta[property="og:title"]')
      ?.setAttribute('content', title)
    document
      .querySelector('meta[name="twitter:title"]')
      ?.setAttribute('content', title)

    if (to.meta.description) {
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute('content', String(to.meta.description))
      document
        .querySelector('meta[property="og:description"]')
        ?.setAttribute('content', String(to.meta.description))
      document
        .querySelector('meta[name="twitter:description"]')
        ?.setAttribute('content', String(to.meta.description))
    }
  })
})

export default router
