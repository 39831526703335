import axios, { type InternalAxiosRequestConfig } from 'axios'
import store from '@/store'
import router from '@/router'
import renewExpiringToken from '@/services/auth0/renewExpiringToken'
import debug from '@/helpers/debug'
import { GLOBAL_ERROR, MAINTENANCE } from '@/store/mutation-types'

axios.defaults.baseURL = import.meta.env.VITE_SWIFTAID_API_URL

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    debug('request:', config, 'info')
    if (localStorage.getItem('testEnv') === 'true') {
      config.headers.set(
        'Authorization',
        `Bearer ${localStorage.getItem('token_id')}`
      )
    } else {
      renewExpiringToken()
      config.headers.set(
        'Authorization',
        // @ts-ignore
        `Bearer ${store.state.auth.idToken}`
      )
    }
    return config
  },
  (error) => {
    debug('request error:', error)
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    debug('response:', response, 'info')
    store.commit(GLOBAL_ERROR, null)
    return response
  },
  (error) => {
    debug('response error:', error, 'info')

    // Don't show global error for conflict errors specific to /me/accounts
    if (
      error.response &&
      error.response.status === 409 &&
      error.response.url.includes('/me/accounts')
    ) {
      return Promise.reject(error)
    }

    if (error.response) {
      // NOTE: handle errors globally with general error msg
      // create instances for urls outside streeva api.
      switch (error.response.status) {
        case 400:
          store.commit(GLOBAL_ERROR, 'AxiosError.BadRequest')
          break
        case 401:
          store.commit(GLOBAL_ERROR, 'AxiosError.Unauthorised')
          break
        case 403:
          store.commit(GLOBAL_ERROR, 'AxiosError.Forbidden')
          break
        case 404:
          store.commit(GLOBAL_ERROR, 'AxiosError.NotFound')
          break
        case 409:
          store.commit(GLOBAL_ERROR, 'AxiosError.Default')
          break
        case 500:
          store.commit(GLOBAL_ERROR, 'AxiosError.Server')
          break
        case 503:
          store.commit(GLOBAL_ERROR, 'AxiosError.ServiceUnavailable')
          store.commit(MAINTENANCE, true)
          router.replace({ name: 'Maintenance' }).catch(() => {})
          break
        default:
          store.commit(GLOBAL_ERROR, 'AxiosError.Default')
      }
    }
    return Promise.reject(error)
  }
)
