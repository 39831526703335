import { createI18n } from 'vue-i18n'
import en from './en'

const messages = { en }

export const options = {
  legacy: false,
  locale: 'en',
  missingWarn: false,
  messages,
}

export default createI18n(options)
