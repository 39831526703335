import { createStore, createLogger } from 'vuex'
import state from '@/store/state'
import mutations from '@/store/mutations'
import actions from '@/store/actions'
import getters from '@/store/getters'
import auth from '@/store/modules/auth'
import donor from '@/store/modules/donor'
import accounts from '@/store/modules/accounts'

const store = createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {
    auth,
    donor,
    accounts,
  },
  plugins: import.meta.env.MODE !== 'production' ? [createLogger()] : [],
})

export default store
