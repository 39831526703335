<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style lang="scss" scoped>
.auth-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: auto;

  @media (min-width: 576px) {
    height: calc(100vh - 250px);
    max-width: 650px;
  }
}
</style>
