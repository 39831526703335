import { delay } from 'lodash'
import auth0 from '@/services/auth0'
import { sendExceptionToAnalytics } from '@/services/googleAnalytics/errorHandling'
import { HELPERS } from '@/helpers'
import {
  SET_LOADING,
  GLOBAL_ERROR,
  SET_AUTH,
  SET_TOKEN,
  SET_EMAIL_SENT_STATUS,
  SET_FORM_SUBMITTED_STATUS,
  SET_AUTH_EMAIL,
  SET_BUTTON_LOADING_STATUS,
  SET_IS_VALID_EMAIL,
  SET_FORM_CHANGED_STATUS,
  RESET_REGISTRATION_STATE,
} from '@/store/mutation-types'
import { AUTH_CONFIG } from '@/services/auth0/auth0-variables'
import type { Commit } from 'vuex'

export default {
  storeToken: (
    context: any,
    authResult: { expiresIn: any; accessToken: any }
  ) => {
    if (!authResult) throw new Error('authResult is required')

    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    )
    localStorage.setItem('loggedIn', 'true')
    localStorage.setItem('expires_at', expiresAt)
    context.commit(SET_AUTH, true)
    context.commit(SET_TOKEN, authResult.accessToken)
  },
  auth0PasswordlessStart: (context: any, payload: any) => {
    return new Promise((resolve, reject) => {
      auth0.passwordlessStart(
        {
          connection: 'email',
          send: payload.send,
          email: payload.email,
          authParams: {
            redirectUri: `${window.location.origin}/callback?callbacklandingpage=${context.state.callbackLandingPage}`,
          },
        },
        function (err: any, res: any) {
          if (err) {
            context.commit(GLOBAL_ERROR, err.description)
            sendExceptionToAnalytics(
              `/src/store/modules/auth.js#auth0PasswordlessStart: ${err.error}`
            )
            reject(err)
            return reject(err)
          } else {
            resolve(res)
          }
        }
      )
    })
  },
  auth0PasswordlessLogin: (context: any, payload: any) => {
    return new Promise((resolve, reject) => {
      auth0.passwordlessLogin(
        {
          connection: 'email',
          email: payload.email,
          verificationCode: payload.verificationCode,
        },
        (err: any, res: any) => {
          if (err) {
            context.commit(GLOBAL_ERROR, err.description)
            sendExceptionToAnalytics(
              `/src/store/modules/auth.js#auth0PasswordlessLogin: ${err.error}`
            )
            reject(err)
          } else {
            resolve(res)
          }
        }
      )
    })
  },
  auth0CheckSession: ({ commit }: { commit: Commit }) => {
    if (import.meta.env.VITE_MAINTENANCE) return

    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        commit(
          GLOBAL_ERROR,
          'Timed out trying to contact the authentication server, please try again.'
        )
        reject()
      }, 10000)

      auth0.checkSession(
        {
          audience: AUTH_CONFIG.audience,
          scope: AUTH_CONFIG.scope,
        },
        async (error: any, authResult: any) => {
          clearTimeout(timeout)

          if (error) {
            reject(error)
            return
          }

          resolve(authResult)
        }
      )
    })
  },
  storeMockToken: (context: any) => {
    localStorage.setItem('loggedIn', 'true')
    localStorage.setItem(
      'expires_at',
      JSON.stringify(10 * 1000 + new Date().getTime())
    )
    context.commit(SET_AUTH, true)
    context.commit(SET_TOKEN, 'swiftaid-testtoken-1234567890')
  },
  auth0Logout: (context: any, logoutUrl = HELPERS.logoutUrl) => {
    context.commit(SET_LOADING, true)
    auth0.logout({
      returnTo: logoutUrl,
      clientID: import.meta.env.VITE_AUTH_CLIENT_ID,
    })
    localStorage.removeItem('loggedIn')
    localStorage.removeItem('expires_at')
    context.commit(SET_AUTH, null)
    context.commit(SET_TOKEN, null)
  },
  sendLoginEmail: async (context: any, payload: any) => {
    context.commit(SET_AUTH_EMAIL, payload.email)
    context.commit(SET_BUTTON_LOADING_STATUS, true)

    try {
      await context.dispatch('auth0PasswordlessStart', {
        email: payload.email,
        send: payload.send,
      })
      delay(() => {
        context.commit(SET_FORM_SUBMITTED_STATUS, true)
        context.commit(SET_BUTTON_LOADING_STATUS, false)
      }, 1000)
    } catch (error) {
      context.commit(SET_BUTTON_LOADING_STATUS, false)
    }
  },
  setEmailSentStatus: (context: any, payload: any) => {
    context.commit(SET_EMAIL_SENT_STATUS, payload)
  },
  setFormSubmittedStatus: (context: any, payload: any) => {
    context.commit(SET_FORM_SUBMITTED_STATUS, payload)
  },
  setAuthEmail: (context: any, payload: any) => {
    context.commit(SET_AUTH_EMAIL, payload)
  },
  setButtonLoadingStatus: (context: any, payload: any) => {
    context.commit(SET_BUTTON_LOADING_STATUS, payload)
  },
  setIsValidEmail: (context: any, payload: any) => {
    context.commit(SET_IS_VALID_EMAIL, payload)
  },
  setFormChangedStatus: (context: any, payload: any) => {
    context.commit(SET_FORM_CHANGED_STATUS, payload)
  },
  resetRegistrationState: (context: any) => {
    context.commit(RESET_REGISTRATION_STATE)
  },
  getToken: (context: any) =>
    new Promise((resolve) => {
      resolve(context.state.idToken)
    }),
}
