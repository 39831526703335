<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import type { Account } from '@/types'

const store = useStore()

const props = defineProps<{
  account: Account
  accountType: string
}>()

const isToBeDeletedClass = computed(() => {
  return props.account.id === store.state.accounts.accountToBeDeleted
    ? 'account--to-be-deleted'
    : ''
})

const isPhoneOrEmail = computed(() => {
  return (
    props.accountType === store.state.accounts.accountTypeConstant.email ||
    props.accountType === store.state.accounts.accountTypeConstant.phone
  )
})

const isCard = computed(() => {
  return props.accountType === store.state.accounts.accountTypeConstant.card
})

const isPar = computed(() => {
  return props.account.type === store.state.accounts.accountTypeConstant.par
})
</script>

<template>
  <div class="dash-account" :class="[account.cssClass, isToBeDeletedClass]">
    <div class="dash-account__icon">
      <img class="dash-account__image" alt="Account icon" :src="account.logo" />
    </div>
    <div class="dash-account__info">
      <div class="dash-account__ref">
        <span v-if="isCard">**** **** **** {{ account.last4 }}</span>
        <span v-if="isPhoneOrEmail">
          {{ account.id }}
        </span>
      </div>
      <div v-if="account.expiryDate" class="dash-account__exp">
        {{ account.expiryDate }}
      </div>
    </div>
    <div class="dash-account__actions">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
.dash-account {
  $this: &;
  $smallPadding: 0.75rem;
  $largePadding: 1rem;

  background: var(--white);
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;

  &--expired {
    #{$this}__exp {
      color: var(--theme-red);
      font-weight: bold;
    }
  }

  &--unlinked {
    #{$this}__exp {
      color: var(--theme-red);
      font-weight: bold;
    }
  }

  &--scheme-visa {
    #{$this}__icon {
      background-color: #162d6d;
    }

    #{$this}__image {
      width: 60px;
    }
  }

  &--scheme-mastercard {
    #{$this}__icon {
      background-color: #13224b;
    }
  }

  &--scheme-amex {
    #{$this}__icon {
      background-color: #016fd0;
    }

    #{$this}__image {
      width: 70px;
    }
  }

  &--scheme-error {
    #{$this}__icon {
      background-color: var(--theme-red);
    }
  }

  &--to-be-deleted {
    box-shadow:
      0 0 35px -20px var(--theme-red),
      0 0 0 1px rgba(#e96b7a, 0.25) inset;
    background-color: mix(#e96b7a, #fff, 10%);
    color: var(--theme-red);

    #{$this}__icon {
      box-shadow: 0 0 0 1px var(--theme-red) inset;
    }
  }

  &--placeholder {
    opacity: 0.7;
  }

  &--placeholder &__icon {
    background-color: var(--white);
    border-right: 2px solid var(--light-grey);
  }

  &__icon {
    background-color: var(--black);
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @media (min-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }

  &__image {
    width: 50px;
    height: auto;
  }

  &__info {
    padding: $smallPadding;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @media (min-width: 768px) {
      padding: $largePadding;
    }
  }

  &__ref {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;

    @media (min-width: 768px) {
      max-width: none;
    }
  }

  &__actions {
    padding: $smallPadding;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      padding: $largePadding;
    }
  }

  $menu-color: lighten(#f8f8f8, 12%);
  &__actions--open &__menu-button {
    background-color: $menu-color;
  }

  &__menu-button {
    color: var(--black);
    display: block;
    position: relative;
    padding: 0 10px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: $menu-color;
    }
  }

  &__menu {
    position: absolute;
    z-index: 2;
    top: 23px;
    right: 0;
    width: 140px;
    padding: 4px;
    background: $menu-color;
    border-radius: 2px;
    box-shadow: 0 6px 20px -10px darken($menu-color, 12%);
  }

  &__open-menu-actions {
    text-align: right;
  }

  &__open-menu-items {
    font-size: 14px;
    letter-spacing: 0.3px;
    font-weight: 600;

    a {
      color: var(--black);
      display: block;
      padding: 6px;
      border-radius: 3px;

      &:hover {
        background-color: darken($menu-color, 5%);
      }
    }
  }
}
</style>
