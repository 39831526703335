import {
  TOGGLE_SIDEBAR,
  SET_APP_LOADING,
  SET_LOADING,
  SET_REQUEST_LOADING,
  GLOBAL_ERROR,
  IS_SETTINGS_PAGE,
  SET_PRINT_DATA,
  SET_TERMS_MODAL_VISIBILITY,
  SET_PRIVACY_MODAL_VISIBILITY,
  SET_REFERRAL_EMAIL,
  MAINTENANCE,
} from '@/store/mutation-types'

export default {
  [TOGGLE_SIDEBAR](state: any) {
    state.sidebar.opened = !state.sidebar.opened
  },
  [SET_APP_LOADING](state: any, payload: any) {
    state.appLoading = payload
  },
  [SET_LOADING](state: any, payload: any) {
    state.isLoading = payload
  },
  [SET_REQUEST_LOADING](state: any, payload: any) {
    state.requestLoading = payload
  },
  [GLOBAL_ERROR](state: any, payload: any) {
    state.globalError = payload
  },
  [IS_SETTINGS_PAGE](state: any, payload: any) {
    state.isSettingsPage = payload
  },
  [SET_PRINT_DATA](state: any, payload: any) {
    state.printData = payload
  },
  [SET_TERMS_MODAL_VISIBILITY](state: any, payload: any) {
    state.termsModalIsVisible = payload
  },
  [SET_PRIVACY_MODAL_VISIBILITY](state: any, payload: any) {
    state.privacyModalIsVisible = payload
  },
  [SET_REFERRAL_EMAIL](state: any, payload: any) {
    state.referralEmail = payload
  },
  [MAINTENANCE](state: any, payload: any) {
    state.maintenance = payload
  },
}
