import axios from 'axios'
import isNil from 'lodash/isNil'

export default {
  deleteSwiftAidAccount() {
    return axios.delete(`/me`)
  },
  // NOTE: user
  fetchUserStatements() {
    return axios.get(`/me/statements`)
  },
  postDonorInformation(payload: any) {
    return axios.post(`/me/details`, payload)
  },
  fetchUser(referralLink: string | null = null) {
    const queryParams = []

    // Referral link
    if (!isNil(referralLink)) {
      queryParams.push(`ref=${referralLink}`)
    }

    queryParams.push('createUser=false')

    // Convert query params to string
    let queryParamsString = ''
    if (queryParams.length > 0) {
      queryParamsString = `?${queryParams.join('&')}`
    }

    return axios.get(`/me${queryParamsString}`)
  },
  // NOTE: authorisation
  fetchAuth() {
    return axios.get('/me/authorisation', {
      validateStatus: (status) => {
        return (
          (status >= 200 && status < 300) || status === 404 || status === 409
        )
      },
    })
  },
  postAuth() {
    return axios.post('/me/authorisation')
  },
  deleteAuth(payload: { endDate: string }) {
    const endDate = payload.endDate || ''
    return axios.delete(`/me/authorisation?endDate=${endDate}`, {
      validateStatus: (status) => {
        return (
          (status >= 200 && status < 300) || status === 404 || status === 409
        )
      },
    })
  },
  // NOTE: donations
  fetchDonorDonations() {
    return axios.get('/me/donations')
  },
  // NOTE: PAR
  postPar(payload: any) {
    return axios.post('/tokens', payload, {
      baseURL: import.meta.env.VITE_SWIFTAID_API_URL_PAR,
    })
  },
  // NOTE: Cards
  postCard(payload: any) {
    return axios.post('/cards', payload, {
      baseURL: import.meta.env.VITE_SWIFTAID_API_URL_CARD_LINKED,
    })
  },
  deleteCard(payload: any) {
    return axios.delete(`/me/accounts/${payload}`)
  },
  // NOTE: account
  deleteAccount(payload: any) {
    return axios.delete(`/me/accounts/${payload}`)
  },
  fetchBankSession(payload: { providerId: any; redirectUrl: any }) {
    return axios.post(
      `/me/accounts/bankaccount/session/${payload.providerId}`,
      {
        redirectUrl: payload.redirectUrl,
      }
    )
  },
  postAccount(payload: any) {
    return axios.post(`/me/accounts`, payload)
  },
  postAccountVerificationConfirmationCode(payload: any) {
    return axios.post(`/account-verify?confirmation_code=${payload}`)
  },
}
