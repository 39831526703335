<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()

const hasAccounts = computed(() => store.state.accounts.hasAccounts)
const globalError = computed(() => store.getters.globalError)
const onPage = computed(() => route.name === 'Accounts')
const showNotification = computed(
  () => !globalError.value && !onPage.value && !hasAccounts.value
)
</script>

<template>
  <router-link
    v-if="showNotification"
    :to="{ name: 'Accounts' }"
    class="notification-alert alert notification-alert--error"
    aria-label="card notification banner"
  >
    <div class="d-flex align-items-center">
      <div class="notification-alert__icon d-none d-sm-block">
        <i class="far fa-credit-card notification-alert__cc-icon"></i>
      </div>
      <span class="notification-alert__body">
        You have no linked accounts, please
        <strong>click here</strong>
        to add one.
      </span>
    </div>
  </router-link>
</template>
