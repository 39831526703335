<script setup lang="ts">
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import Menu from '@/components/generic/menu/Menu.vue'

const store = useStore()

const sidebarOpened = computed(() => store.state.sidebar.opened)

watch(sidebarOpened, () => {
  if (sidebarOpened.value) {
    document.body.classList.add('overflow-hidden')
  } else {
    document.body.classList.remove('overflow-hidden')
  }
})

const toggleSidebar = () => {
  store.commit('TOGGLE_SIDEBAR')
}
</script>

<template>
  <transition name="fade">
    <nav v-if="sidebarOpened" class="mobile-menu" @click="toggleSidebar">
      <Menu />
      <div class="mobile-menu__overlay" @click="toggleSidebar"></div>
    </nav>
  </transition>
</template>
