<script setup lang="ts">
import { ref } from 'vue'
import { useStore } from 'vuex'
import swiftaidLogo from '@/assets/images/main/logo.svg'

const store = useStore()

const currentYear = ref(new Date().getFullYear())
const termsLink = ref(import.meta.env.VITE_LEGAL_TERMS_LINK)
const privacyLink = ref(import.meta.env.VITE_LEGAL_PRIVACY_LINK)

const setTermsModalVisibility = (visibility: boolean) => {
  store.commit('SET_TERMS_MODAL_VISIBILITY', visibility)
}

const setPrivacyModalVisibility = (visibility: boolean) => {
  store.commit('SET_PRIVACY_MODAL_VISIBILITY', visibility)
}
</script>

<template>
  <div class="footer layout__footer">
    <a href="https://www.swiftaid.co.uk" target="_blank" class="d-flex">
      <img
        :src="swiftaidLogo"
        class="footer__logo mb-4 mx-auto"
        alt="An image of the Swiftaid logo"
      />
    </a>
    <ul class="list-inline small d-flex justify-content-around">
      <li class="list-inline-item">
        <a
          :href="termsLink"
          data-bs-toggle="modal"
          data-bs-target="#termsModal"
          data-testid="legal-terms-link"
          class="text-decoration-none"
          @click.prevent="setTermsModalVisibility(true)"
        >
          {{ $t('LoginRegister.Footer.Terms') }}
        </a>
      </li>
      <li class="list-inline-item">
        <a
          :href="privacyLink"
          data-bs-toggle="modal"
          data-bs-target="#privacyModal"
          data-testid="legal-privacy-link"
          class="text-decoration-none"
          @click.prevent="setPrivacyModalVisibility(true)"
        >
          {{ $t('LoginRegister.Footer.Privacy') }}
        </a>
      </li>
    </ul>
    <p class="footer__lightline small mb-4 text-center">
      &copy; {{ currentYear }}
      <!-- eslint-disable-next-line prettier/prettier - Wrapping the `.` to a new line introduces a space -->
      <a href="https://streeva.com" class="text-decoration-none">
        {{ $t('LoginRegister.Footer.Streeva') }}
      </a>
      . {{ $t('LoginRegister.Footer.Statement') }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
/* purgecss ignore */
.footer {
  &__logo {
    margin-top: var(--spacer);
    max-width: 112px;
    min-height: 17px;
  }
}

.list-inline-item {
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }
}
</style>
