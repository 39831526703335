<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import Header from '@/components/views/manage-account/Header.vue'
import CodeForm from '@/components/views/manage-account/Login/CodeForm.vue'
import ResendButton from '@/components/views/manage-account/Login/ResendButton.vue'
import envelopeCode from '@/assets/images/envelope-code.svg'
import envelopeMagicLink from '@/assets/images/envelope-magiclink.svg'
import { LoginMethod } from '@/types'

const store = useStore()

let imgSrc = ref(envelopeCode)
let header = ref('ManageAccount.Login.End.Header')
let para = ref('ManageAccount.Login.End.Paragraph')

const loginMethod = computed(() => store.state.auth.loginMethod)
const authEmail = computed(() => store.state.auth.authEmail)

onMounted(() => {
  if (loginMethod.value === LoginMethod.LINK) {
    imgSrc.value = envelopeMagicLink
    header.value = 'ManageAccount.Login.End.MagicLinkHeader'
    para.value = 'ManageAccount.Login.End.MagicLinkParagraph'
  }
})

const reset = () => {
  store.commit('RESET_LOGIN_METHOD')
  store.commit('SET_FORM_SUBMITTED_STATUS', false)
}
</script>

<template>
  <div>
    <Header
      :img-src="imgSrc"
      img-alt="Open envelope with a code inside"
      img-width="97px"
    >
      <template #custom>
        <h1 class="h2 mb-4 fw-bold">{{ $t(header) }}</h1>
        <p class="text-balance fs-5 mb-0">
          {{ $t(para, { email: authEmail }) }}
        </p>
      </template>
    </Header>

    <div class="row justify-content-center">
      <div class="col text-center">
        <CodeForm v-if="loginMethod === LoginMethod.CODE" />

        <ResendButton
          :class="[
            loginMethod === LoginMethod.CODE ? 'mt-5 mb-3' : 'mt-3 mb-5',
          ]"
        />

        <p class="text-balance">
          {{ $t('ManageAccount.Login.End.Note') }}
        </p>

        <p>
          <button class="text-link" @click="reset">
            {{ $t('ManageAccount.Login.End.ResetLink') }}
          </button>
        </p>
      </div>
    </div>
  </div>
</template>
