import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import { LoginMethod } from '@/types'

const isAuthenticated = () => {
  try {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') ?? '0')
    return (
      new Date().getTime() < expiresAt &&
      localStorage.getItem('loggedIn') === 'true'
    )
  } catch (error) {
    return false
  }
}

const callbackLandingPage = () => {
  return sessionStorage.getItem('callbackLandingPage')
}

export default {
  namespaced: false,
  state: {
    authenticated: isAuthenticated(),
    idToken: null,
    loginMethod: LoginMethod.CODE,
    emailSent: false,
    formSubmitted: false,
    hasFormChanged: false,
    isValidEmail: false,
    authEmail: '',
    btnLoading: false,
    callbackLandingPage: callbackLandingPage(),
  },
  mutations,
  actions,
  getters,
}
