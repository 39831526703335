<script setup lang="ts">
import { onBeforeMount, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import giftaidItBlack from '@/assets/images/giftaidit-black.svg'
import Header from '@/components/views/manage-account/Header.vue'

const store = useStore()

onBeforeMount(() => {
  window.addEventListener('beforeunload', onUnload)
})

onUnmounted(() => {
  logout()
})

const logout = () => {
  store.commit('auth0Logout', 'https://www.swiftaid.co.uk')
}

const onUnload = () => {
  logout()
}
</script>

<template>
  <div class="container">
    <Header
      :img-src="giftaidItBlack"
      img-alt="giftaid it"
      img-width="142px"
      img-margin-bottom="mb-4"
      :header="$t('ManageAccount.CloseAccountConfirm.Header')"
    >
      <template #custom>
        <p class="fs-5">
          {{ $t('ManageAccount.CloseAccountConfirm.Para1') }}
        </p>
        <p class="text-balance">
          {{ $t('ManageAccount.CloseAccountConfirm.Para2') }}
          <a
            href="https://www.swiftaid.co.uk/legal/privacy"
            class="text-decoration-none"
          >
            {{ $t('ManageAccount.CloseAccountConfirm.Para2LinkText') }}
          </a>
        </p>
      </template>
    </Header>

    <div class="row mb-4">
      <div class="col d-flex">
        <button
          class="sa-btn sa-btn-primary-solid sa-btn-sm text-decoration-none d-inline-flex mx-auto"
          @click.prevent="logout"
          v-text="$t('ManageAccount.CloseAccountConfirm.Button')"
        ></button>
      </div>
    </div>
  </div>
</template>
