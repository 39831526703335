<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const isAuth = computed(() => store.state.auth.authenticated)

const logout = () => {
  store.dispatch('auth0Logout')
}
</script>

<template>
  <ul class="sidebar-menu menu">
    <li class="menu__item">
      <router-link class="sidebar-link menu__link" :to="{ name: 'Dashboard' }">
        <i
          class="sidebar-menu-item-icon menu__item-icon fas fa-fw fa-hand-holding-heart"
        ></i>
        {{ $t('Menu.Dashboard') }}
      </router-link>
    </li>
    <li class="menu__item">
      <router-link class="sidebar-link menu__link" :to="{ name: 'Accounts' }">
        <i
          class="sidebar-menu-item-icon menu__item-icon fas fa-fw fa-credit-card"
        ></i>
        {{ $t('Menu.Accounts') }}
      </router-link>
    </li>
    <li class="menu__item">
      <router-link class="sidebar-link menu__link" :to="{ name: 'Statements' }">
        <i
          class="sidebar-menu-item-icon menu__item-icon fas fa-fw fa-file-alt"
        ></i>
        {{ $t('Menu.Statements') }}
      </router-link>
    </li>
    <li class="menu__item">
      <router-link class="sidebar-link menu__link" :to="{ name: 'Settings' }">
        <i class="sidebar-menu-item-icon menu__item-icon fas fa-fw fa-user"></i>
        {{ $t('Menu.Settings') }}
      </router-link>
    </li>
    <li v-if="isAuth" class="menu__item">
      <a class="sidebar-link menu__link" href="/logout" @click.prevent="logout">
        <i
          class="sidebar-menu-item-icon menu__item-icon fa fa-sign-out-alt fa-fw"
          style="font-size: 1.5em"
        ></i>
        {{ $t('Buttons.Logout') }}
      </a>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
i {
  position: relative;
  z-index: 0;
}
</style>
