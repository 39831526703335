<script setup lang="ts">
import { ref, computed, onMounted, type Ref, type ComputedRef } from 'vue'
import { useStore } from 'vuex'
import mapValues from 'lodash/mapValues'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import ApiErrorNotification from '@/components/generic/ApiErrorNotification.vue'
import DonationsSummary from '@/components/views/dashboard/donations/DonationsSummary.vue'
import Placeholder from '@/components/views/dashboard/donations/Placeholder.vue'
import Donations from '@/components/views/dashboard/donations/Donations.vue'

type FinancialYear = {
  period: string
  donations: any[]
  totalDonations: number
  totalGiftAid: number
}

type FinancialYears = FinancialYear[]

const store = useStore()

const financialPeriod: Ref<string | null> = ref(null)
const years: Ref<FinancialYear[]> = ref([])
const yearsAdded = ref(false)
const zeroDonorDonations = ref(true)
const isScreenReady = ref(false)
const apiError = ref(false)

const financialYears: ComputedRef<FinancialYears> = computed(
  () => store.getters.financialYears
)

const token: ComputedRef<string> = computed(() => store.getters.token)

const financialYear: Ref<FinancialYear | null | undefined> = computed(() => {
  if (isNil(financialPeriod.value)) return null

  // @ts-ignore
  return find(financialYears.value, { period: financialPeriod.value })
})

/**
 * Workaround to support tree-shaking on chained function
 * imports. See https://github.com/lodash/lodash/issues/3298
 */
const chainableFunctions = {
  sortBy,
  reverse,
}

const chain = <T,>(input: T) => {
  let value = input
  const wrapper = {
    ...mapValues(chainableFunctions, (f) => (...args: any) => {
      // @ts-ignore
      value = f(value, ...args)
      return wrapper
    }),
    value: () => value,
  } as any
  return wrapper as typeof wrapper & T
}

const sortYears = (_years: FinancialYears) => {
  years.value = chain(_years).sortBy('period').reverse().value()
  yearsAdded.value = true
}

const loopFinancialYears = (
  financialYears: FinancialYear[],
  period: string | null = null
) => {
  let maxYear = 0

  financialYears.forEach((fy: FinancialYear) => {
    zeroDonorDonations.value = fy.donations.length <= 0
    financialYear.value = fy

    if (isNil(period)) {
      // @ts-ignore
      const splitYear = parseInt(fy.period.split('-').pop(1))

      if (splitYear > maxYear) {
        maxYear = splitYear
        financialPeriod.value = fy.period
      }
    }
  })

  if (!yearsAdded.value) {
    sortYears(financialYears)
  }
}

const donations = async () => {
  try {
    await store.dispatch('fetchDonorDonations')
    loopFinancialYears(financialYears.value)
    isScreenReady.value = true
  } catch (error) {
    console.error(error)
    apiError.value = true
  }

  store.commit('SET_LOADING', false)
}

onMounted(() => {
  if (token.value === null) {
    setTimeout(() => {
      donations()
    }, 2500)
  } else {
    donations()
  }
})
</script>

<template>
  <div role="main">
    <ApiErrorNotification
      v-if="apiError"
      link="Dashboard"
      aria-label="Donations API error"
    >
      An error has occurred while fetching donations, please
      <strong>try again</strong>
      .
    </ApiErrorNotification>
    <transition v-if="isScreenReady" name="fade" mode="out-in" appear>
      <Placeholder v-if="zeroDonorDonations" />
      <div v-else id="donationList">
        <div v-if="financialYear">
          <div class="row mb-4">
            <div id="financialYearPicker" class="col">
              <label for="year" class="mb-0 me-3 fw-bold">
                {{ $t('Dashboard.Donations.SelectTaxYearLabel') }}
              </label>
              <select
                id="year"
                v-model="financialPeriod"
                class="form-select col-6 col-md-3"
                size="1"
                name="year"
                aria-describedby="year"
                @change="loopFinancialYears(financialYears, financialPeriod)"
              >
                <option
                  v-for="yearObject in years"
                  :key="yearObject.period"
                  :value="yearObject.period"
                >
                  {{ yearObject.period }}
                </option>
              </select>
            </div>
          </div>
          <DonationsSummary
            :total-donations="financialYear.totalDonations"
            :total-gift-aid="financialYear.totalGiftAid"
            role="region"
          />
          <Donations :donor-donations="financialYear" />
        </div>
      </div>
    </transition>
  </div>
</template>
