<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import Account from '@/components/views/manage-account/Account.vue'
import AccountMenu from '@/components/views/manage-account/AccountMenu.vue'
import { type Account as AccountType } from '@/types'

const store = useStore()

interface AccountsProp {
  accounts: Array<AccountType>
}

const props = defineProps<AccountsProp>()

const placeholderAccount = computed(
  () => store.state.accounts.placeholderAccount
)

const accountsCollection = computed(() => props.accounts.flat())
</script>

<template>
  <section
    v-if="accountsCollection.length || placeholderAccount"
    class="accounts"
    :class="{ 'accounts--loading': placeholderAccount }"
  >
    <slot name="header" />

    <div v-if="placeholderAccount" class="accounts__loader">
      <div class="accounts__spinner spinner"></div>
    </div>

    <Account
      v-for="account in accountsCollection"
      :key="account.id"
      :account="account"
      class="col-12 rounded shadow mb-4"
      :class="{
        'accounts--email': account.type === 'emailAddress',
        'accounts--card': account.type === 'card',
      }"
      data-testid="account"
    >
      <span
        v-if="account.isPrimary"
        class="badge rounded-pill text-bg-secondary"
      >
        Primary
      </span>

      <AccountMenu
        v-if="!account.isPrimary"
        :account-id="account.id"
        @click="store.commit('accounts/SET_CURRENT_ACCOUNT_ID', account.id)"
      >
        <slot name="menu-items" />
      </AccountMenu>
    </Account>

    <slot name="footer" />
  </section>
</template>

<style lang="scss">
.accounts {
  transition: opacity 0.5s ease-in-out;

  &--loading {
    opacity: 0.5;
    position: relative;
    min-height: 120px;
  }

  &__loader {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__spinner {
    margin: 0;
    zoom: 0.4;
    border-width: 8px;
  }
}
</style>
