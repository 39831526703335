import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: false,
  state: {
    financialYears: null,
    authorisation: {},
    swiftaidAuthorised: true,
    userDetails: {
      accounts: [],
    },
    banks: [],
    bank: null,
    statements: null,
    referralLink: null,
  },
  mutations,
  actions,
  getters,
}
