<script setup lang="ts">
import { onMounted, computed, ref, watch } from 'vue'
import { isEmpty } from 'lodash'
import axios from 'axios'
import { useStore } from 'vuex'
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/yup'
import { sendExceptionToAnalytics } from '@/services/googleAnalytics/errorHandling'
import { titleCase } from '@/helpers/utils'
import Countries from '@/components/helpers/countries.json'
import type { DonorInformation, AddressFromLookUpService } from '@/types'
import {
  donorInformationSchema,
  DEFAULT_DONOR_COUNTRY,
  GIBRALTAR_POSTCODE,
} from '@/types'
import FormLabel from '@/components/views/manage-account/FormLabel.vue'
import FieldError from '@/components/views/manage-account/FieldError.vue'

defineProps({
  formType: {
    type: String,
    default: 'edit',
  },
})

const emit = defineEmits(['cancelEdit'])

const store = useStore()

const addressLine1Input = ref<HTMLInputElement | null>(null)

const btnLoading = ref(false)
const postcodeBtnLoading = ref(false)
const countriesList = ref(Countries)
const addresses = ref<AddressFromLookUpService[]>([])
const address = ref<AddressFromLookUpService>({})
const addressFormVisible = ref(false)
const showAddressSelector = ref(false)
const postcodeNotFound = ref(false)
const postcodeLookupError = ref(false)
const hasRequiredValues = ref(false)

const user = computed(() => {
  return store.state.donor.userDetails
})

const isUk = computed(() => {
  return country.value === DEFAULT_DONOR_COUNTRY
})

const hasAddresses = computed(() => {
  return !!addresses.value.length
})

const addressCount = computed(() => {
  const pluralised =
    addresses.value.length === 1 ? 'address found' : 'addresses found'
  return `${addresses.value.length} ${pluralised}`
})

// Form validation
const validationSchema = toTypedSchema(donorInformationSchema)
const { handleSubmit, errors, defineField, resetForm } = useForm({
  validationSchema,
  validateOnMount: false,
})

const [firstname] = defineField('firstname')
const [lastname] = defineField('lastname')
const [addressLine1] = defineField('addressLine1')
const [addressLine2] = defineField('addressLine2')
const [city] = defineField('city')
const [county] = defineField('county')
const [country] = defineField('country')
const [postcode] = defineField('postcode')
const invalid = computed(() => {
  return !isEmpty(errors.value)
})
const failed = ref(false)

watch(
  () => country.value,
  (country) => {
    if (country !== DEFAULT_DONOR_COUNTRY) {
      handleNonUKCountry()
    }

    if (country === 'Gibraltar') {
      postcode.value = GIBRALTAR_POSTCODE
      formatPostcode()
    }
  }
)

const postDonorInformation = handleSubmit(async (values) => {
  btnLoading.value = true
  try {
    await store.dispatch('postDonorInformation', {
      firstname: values.firstname,
      lastname: values.lastname,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      city: values.city,
      county: values.county,
      postcode: values.postcode,
      country: values.country,
    } as DonorInformation)
    emit('cancelEdit')
  } catch (error) {
    failed.value = true
  }
})

const addressLookup = async () => {
  postcodeLookupError.value = false
  postcodeNotFound.value = false

  if (!isUk.value) return

  if (!postcode.value) return

  const strippedPostcode = (postcode.value as string).replace(/\s+/g, '')

  if (strippedPostcode === GIBRALTAR_POSTCODE) {
    country.value = 'Gibraltar'
    return
  }

  try {
    showAddressSelector.value = true
    addresses.value = []
    postcodeBtnLoading.value = true
    await resetAddressForm()
    const postcodeLookupUrl = `https://api.ideal-postcodes.co.uk/v1/postcodes/${strippedPostcode}?api_key=${
      import.meta.env.VITE_IDEAL_POSTCODES_API_KEY
    }`
    const response = await axios.get(postcodeLookupUrl, {
      validateStatus: (status) => {
        return (status >= 200 && status < 300) || status === 404
      },
    })
    switch (response.status) {
      case 404: // Not found
        handlePostcodeNoResult()
        break
      default: // Addresses found
        handlePostcodeResult(response.data.result)
        break
    }
  } catch (error) {
    handlePostcodeError()
    sendExceptionToAnalytics(
      `/src/components/views/manage-account/PersonalDetailsEditForm.vue#addressLookup: ${error}`
    )
  }

  btnLoading.value = false
  postcodeBtnLoading.value = false
}

const selectAddress = () => {
  showAddressForm()

  if (isEmpty(address)) return

  addressLine1.value = address.value.line_1 || ''
  city.value = titleCase(address.value.post_town || '')
  county.value = titleCase(address.value.county || '')
  if (!address.value.line_2) {
    addressLine2.value = ''
  } else if (!address.value.line_3) {
    addressLine2.value = address.value.line_2
  } else if (address.value.line_3) {
    addressLine2.value = `${address.value.line_2}, ${address.value.line_3}`
  }
}

const formatAddress = (address: AddressFromLookUpService) => {
  return [address.line_1, address.line_2, address.county]
    .filter((a) => a)
    .join(', ')
}

const clearAddressAndEnterManually = () => {
  checkRequiredValues()
  clearAddresses()
  clearAddress()

  // showing address form
  showAddressForm()

  // Clear address
  addressLine1.value = ''
  addressLine2.value = ''
  city.value = ''
  county.value = ''

  // Focus cursor on line 1 input, have to wait for
  // address form to be visible
  setTimeout(() => {
    addressLine1Input.value?.focus()
  }, 1000)
}

const clearAddress = () => {
  address.value = {}
}

const clearAddresses = () => {
  addresses.value = []
}

const hideAddressForm = () => {
  showAddressSelector.value = true
  addressFormVisible.value = false
}

const showAddressForm = () => {
  showAddressSelector.value = false
  addressFormVisible.value = true
}

const showPostcodeNotFoundMsg = () => {
  postcodeNotFound.value = true
}

const showPostcodeLookupErrorMsg = () => {
  postcodeLookupError.value = true
}

const checkRequiredValues = () => {
  if (
    firstname.value &&
    lastname.value &&
    postcode.value &&
    addressLine1.value
  ) {
    hasRequiredValues.value = true
  } else {
    hasRequiredValues.value = false
  }
}

const resetAddressForm = () => {
  // Hide the form first
  hideAddressForm()
  // And add a delay if on the settings page,
  // otherwise required errors show briefly.
  return new Promise((resolve) => {
    setTimeout(() => {
      addressLine1.value = ''
      addressLine2.value = ''
      city.value = ''
      county.value = ''
      postcodeNotFound.value = false
      postcodeLookupError.value = false
      clearAddresses()
      clearAddress()
      resolve(null)
    }, 500)
  })
}

const handlePostcodeResult = (result: any) => {
  postcodeNotFound.value = false
  addresses.value = result
  hideAddressForm()
}

const handlePostcodeNoResult = () => {
  showPostcodeNotFoundMsg()
  showAddressForm()
}

const handlePostcodeError = () => {
  showPostcodeLookupErrorMsg()
  showAddressForm()
}

const formatPostcode = () => {
  const re = /(\S*)\s*(\d)/g
  const str = postcode.value as string
  const newstr = str.replace(re, '$1 $2').toUpperCase()
  postcode.value = newstr
}

const handleNonUKCountry = () => {
  showAddressForm()
}

onMounted(() => {
  store.commit('SET_LOADING', false)

  resetForm({ values: user.value?.details })

  showAddressForm()
})
</script>

<template>
  <div>
    <form
      spellcheck="false"
      @submit.prevent="postDonorInformation"
      data-testid="edit-details-form"
    >
      <!-- PERSONAL DETAILS FORM -->
      <div class="form-wrapper">
        <div class="form-group position-relative">
          <FormLabel label-for="firstname">
            {{ $t('LoginRegister.Details.FirstName') }}
          </FormLabel>
          <input
            id="firstname"
            v-model="firstname"
            type="text"
            class="form-control"
            :class="{ input: true, 'is-invalid': errors.firstname?.length }"
            name="firstname"
            aria-describedby="firstname"
            autocomplete="given-name"
            :placeholder="$t('LoginRegister.Details.FirstNamePlaceholder')"
          />
          <FieldError :error="errors.firstname" />
        </div>
        <div class="form-group position-relative">
          <FormLabel label-for="lastname">
            {{ $t('LoginRegister.Details.LastName') }}
          </FormLabel>
          <input
            id="lastname"
            v-model="lastname"
            type="text"
            class="form-control"
            :class="{ input: true, 'is-invalid': errors.lastname?.length }"
            name="lastname"
            aria-describedby="lastname"
            autocomplete="family-name"
            :placeholder="$t('LoginRegister.Details.LastNamePlaceholder')"
          />
          <FieldError :error="errors.lastname" />
        </div>
        <div class="form-group">
          <FormLabel label-for="country">
            {{ $t('LoginRegister.Details.Country') }}
          </FormLabel>
          <select
            id="country"
            v-model="country"
            class="form-select"
            size="1"
            name="country"
            aria-describedby="country"
          >
            <option
              v-for="country in countriesList"
              :key="country"
              :value="country"
            >
              {{ country }}
            </option>
          </select>
        </div>
        <div v-if="isUk" class="form-group postcode-wrapper">
          <div class="row">
            <div class="col-12">
              <FormLabel label-for="postcode">
                {{ $t('LoginRegister.Details.Postcode') }}
              </FormLabel>
            </div>
          </div>
          <div class="row d-flex">
            <div class="col-6">
              <div class="postcode position-relative">
                <input
                  id="postcode"
                  v-model="postcode"
                  type="text"
                  class="form-control col-12 text-uppercase"
                  :class="{
                    input: true,
                    'is-invalid': errors.postcode?.length,
                  }"
                  name="postcode"
                  aria-describedby="postcode"
                  autocomplete="postal-code"
                  :placeholder="$t('LoginRegister.Details.PostcodePlaceholder')"
                  @blur="formatPostcode"
                />
                <FieldError :error="errors.postcode" />
              </div>
            </div>
            <div class="col-6">
              <button
                id="addressButtonSubmit"
                class="sa-btn sa-btn-sm sa-btn-primary-solid sa-btn-block"
                data-testid="address-lookup-button"
                @click.prevent="addressLookup"
              >
                <div
                  v-if="postcodeBtnLoading"
                  class="d-flex align-items-center"
                >
                  <div
                    class="spinner sa-btn-spinner me-2"
                    role="alert"
                    :aria-label="$t('Accessibility.Button.Loading')"
                  ></div>
                  Finding
                </div>
                <span v-else>{{ $t('Buttons.FindAddress') }}</span>
              </button>
            </div>
          </div>
        </div>

        <!-- ADDRESS SELECTOR -->
        <div
          v-if="showAddressSelector"
          class="form-group text-left"
          data-testid="address-select"
        >
          <FormLabel label-for="addressSelector">
            {{ $t('LoginRegister.Details.SelectAddress') }}
          </FormLabel>
          <select
            id="addressSelector"
            v-model="address"
            class="form-select"
            size="1"
            :disabled="!hasAddresses"
            @change="selectAddress"
          >
            <option :value="{}" disabled>
              <span v-if="hasAddresses">{{ addressCount }}</span>
              <span v-else>Finding...</span>
            </option>
            <option v-for="a in addresses" :key="a.udprn" :value="a">
              {{ formatAddress(a) }}
            </option>
          </select>
          <div class="mt-2 text-center small">
            <button
              id="enterAddressManually"
              class="text-link"
              data-testid="enter-address-manually-button"
              @click.prevent="clearAddressAndEnterManually"
            >
              {{ $t('LoginRegister.Details.CannotFindMyAddress') }}
            </button>
          </div>
        </div>

        <!-- ADDRESS LOOKUP ERROR MESSAGE -->
        <div
          v-if="postcodeNotFound || postcodeLookupError"
          id="checkError"
          class="form-group is-invalid"
        >
          <p
            class="small text-center alert"
            :class="{
              'alert-warning': postcodeNotFound,
              'alert-danger': postcodeLookupError,
            }"
            data-testid="address-lookup-error"
          >
            <span v-if="postcodeNotFound">
              {{ $t('LoginRegister.Details.PostcodeNotFound') }}
            </span>
            <span v-else>
              {{ $t('LoginRegister.Details.AddressLookupFailure') }}
            </span>
          </p>
        </div>

        <!-- ADDRESS FORM -->
        <div
          v-if="addressFormVisible"
          id="ownAddress"
          class="text-left"
          data-testid="address-fields"
        >
          <div class="form-group position-relative">
            <FormLabel label-for="addressLine1">
              {{ $t('LoginRegister.Details.AddressLine1') }}
            </FormLabel>
            <input
              id="addressLine1"
              ref="addressLine1Input"
              v-model="addressLine1"
              type="text"
              class="form-control"
              :class="{
                input: true,
                'is-invalid': errors.addressLine1?.length,
              }"
              name="addressLine1"
              aria-describedby="addressLine1"
              autocomplete="address-line1"
              :placeholder="$t('LoginRegister.Details.AddressLine1Placeholder')"
            />

            <FieldError :error="errors.addressLine1" />
          </div>
          <div class="form-group position-relative">
            <FormLabel label-for="addressLine2">
              {{ $t('LoginRegister.Details.AddressLine2') }}
            </FormLabel>
            <input
              id="addressLine2"
              v-model="addressLine2"
              type="text"
              class="form-control"
              :class="{
                input: true,
                'is-invalid': errors.addressLine2?.length,
              }"
              name="addressLine2"
              aria-describedby="addressLine2"
              autocomplete="address-line2"
              :placeholder="$t('LoginRegister.Details.AddressLine2Placeholder')"
            />
            <FieldError :error="errors.addressLine2" />
          </div>
          <div class="form-group position-relative">
            <FormLabel label-for="city">
              {{ $t('LoginRegister.Details.Town') }}
            </FormLabel>
            <input
              id="city"
              v-model="city"
              type="text"
              class="form-control"
              :class="{ input: true, 'is-invalid': errors.city?.length }"
              name="city"
              aria-describedby="city"
              autocomplete="address-level2"
              :placeholder="$t('LoginRegister.Details.TownPlaceholder')"
            />
            <FieldError :error="errors.city" />
          </div>
          <div class="form-group position-relative">
            <FormLabel label-for="county">
              <span v-if="isUk">{{ $t('LoginRegister.Details.County') }}</span>
              <span v-else>
                {{ $t('LoginRegister.Details.StateProvinceCounty') }}
              </span>
            </FormLabel>
            <input
              id="county"
              v-model="county"
              type="text"
              class="form-control"
              :class="{ input: true, 'is-invalid': errors.county?.length }"
              name="county"
              aria-describedby="county"
              autocomplete="address-level1"
              :placeholder="
                $t(
                  isUk
                    ? 'LoginRegister.Details.CountyPlaceholder'
                    : 'LoginRegister.Details.StateProvinceCountyPlaceholder'
                )
              "
            />
            <FieldError :error="errors.county" />
          </div>
          <div v-if="!isUk" class="form-group">
            <FormLabel label-for="postcode">
              {{ $t('LoginRegister.Details.ZipCodePostalCode') }}
            </FormLabel>
            <input
              id="postcode"
              v-model="postcode"
              type="text"
              class="form-control col-4 text-uppercase"
              :class="{
                input: true,
                'is-invalid': errors.postcode?.length,
              }"
              name="postcode"
              aria-describedby="postcode"
              autocomplete="postal-code"
              :placeholder="$t('LoginRegister.Details.PostcodePlaceholder')"
            />
            <FieldError :error="errors.postcode" />
          </div>
        </div>

        <!-- FORM ACTIONS (SAVE/CANCEL) -->
        <div class="mt-4">
          <p v-show="failed" class="text-danger small fw-bold text-center">
            {{ $t('LoginRegister.Details.GenericFieldError') }}
          </p>
          <div v-if="formType === 'create'">
            <button
              id="submit"
              :aria-label="
                $t(
                  invalid
                    ? 'LoginRegister.Details.ButtonLabels.Details.Disabled'
                    : 'Accessibility.Button.Continue'
                )
              "
              class="sa-btn sa-btn-sm sa-btn-primary-solid sa-btn-block me-2"
              type="submit"
            >
              <span>{{ $t('Buttons.Confirm') }}</span>
            </button>
          </div>

          <div v-else class="d-flex">
            <button
              id="submit"
              :disabled="btnLoading"
              class="sa-btn sa-btn-sm sa-btn-primary-solid sa-btn-block me-2"
              type="submit"
            >
              <div v-if="btnLoading" class="d-flex align-items-center">
                <div
                  class="spinner sa-btn-spinner me-2"
                  role="alert"
                  :aria-label="$t('Accessibility.Button.Loading')"
                ></div>
                Saving
              </div>
              <div v-else>
                <span>{{ $t('Buttons.Save') }}</span>
              </div>
            </button>
            <button
              class="sa-btn sa-btn-sm sa-btn-secondary sa-btn-block ms-2"
              data-testid="cancel-edit-button"
              @click="$emit('cancelEdit')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 1rem;
}
</style>
