<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import DetailsForm from '@/views/manage-account/create/details.vue'
import DeclarationForm from '@/views/manage-account/create/declaration.vue'
import PersonalDetails from '@/components/views/dashboard/settings/PersonalDetails.vue'

const store = useStore()

const renewStart = ref(true)
const addAddress = ref(false)
const addAuth = ref(false)

const enterAddress = () => {
  renewStart.value = false
  addAddress.value = true
}

const readAuth = () => {
  renewStart.value = false
  addAddress.value = false
  addAuth.value = true
}

onMounted(async () => {
  store.commit('SET_LOADING', false)
})
</script>

<template>
  <div class="bg-white p-3 p-md-4 settings-personal-details-edit">
    <div v-if="renewStart" id="renewInnerWrapper" aria-label="Personal details">
      <h1 class="auth-headers mb-4">
        {{ $t('Dashboard.Settings.RenewAuth.Heading') }}
      </h1>
      <div class="p-3 mb-4 text-center">
        <PersonalDetails />
      </div>
      <div class="btn-toolbar col col-lg-8 justify-content-center mx-auto">
        <button class="btn btn-primary btn-sm" @click="readAuth">
          {{ $t('Buttons.Yes') }}
        </button>
        <button class="btn btn-secondary btn-sm" @click="enterAddress">
          {{ $t('Buttons.No') }}
        </button>
      </div>
    </div>

    <div v-if="addAddress" class="address-container">
      <h1 class="auth-headers mb-4">
        {{ $t('Dashboard.Settings.Personal.Heading') }}
      </h1>
      <DetailsForm @click="readAuth" />
    </div>

    <div v-if="addAuth" class="declaration mx-auto">
      <DeclarationForm :renew="true" class="mt-0" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.renew-wrapper {
  padding: var(--spacer);

  @media (min-width: 576px) {
    padding: calc(var(--spacer) * 1.5);
  }

  @media (min-width: 768px) {
    max-width: var(--layout-max-width);
    margin: auto;
  }

  @media (min-width: 992px) {
    padding: calc(var(--spacer) * 3);
  }

  .form-wrapper {
    max-width: 100% !important;
  }
}

.address-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.declaration {
  max-width: 550px;
}
</style>

<style lang="scss">
.renew-wrapper {
  padding: var(--spacer);

  .form-wrapper {
    max-width: 100% !important;
    padding: 0;
  }
}
</style>
